import WETH_img from "../assets/images/token/WETH.png";
import ARB_img from "../assets/images/token/ARB.png";
import COMP_img from "../assets/images/token/COMP.png";
import CRV_img from "../assets/images/token/CRV.png";
import DAI_img from "../assets/images/token/DAI.png";
import GMX_img from "../assets/images/token/GMX.png";
import GRT_img from "../assets/images/token/GRT.png";
import LDO_img from "../assets/images/token/LDO.png";
import LINK_img from "../assets/images/token/LINK.png";
import MKR_img from "../assets/images/token/MKR.png";
import TUSD_img from "../assets/images/token/TUSD.png";
import UNI_img from "../assets/images/token/UNI.png";
import USDC_img from "../assets/images/token/USDC.png";
import AVAX_img from "../assets/images/token/avax.svg";
import USDD_img from "../assets/images/token/USDD.png";
import USDT_img from "../assets/images/token/USDT.png";
import WBTC_img from "../assets/images/token/WBTC.png";
import FRAX_img from "../assets/images/token/FRAX.png";
import BTCB_img from "../assets/images/token/btcb.svg";
import VKA_img from "../assets/images/token/vka.png";
import WMATIC_img from "../assets/images/token/wmatic.png";
import { ARB_ID, ETH_ID, AVAX_ID, POLYGON_ID } from "./CHAIN_ID_MAPPING";

export interface IToken {
  symbol: string;
  img?: string;
  title: string;
  chainlink?: { [n: string]: string };
  address: { [n: string]: string };
}

export interface ITokenList {
  [n: string]: IToken;
}

const TOKEN_LIST: ITokenList = {
  USDT: {
    symbol: "USDT",
    img: USDT_img,
    title: "Tether",
    chainlink: {
      [ARB_ID]: "0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7",
      [ETH_ID]: "0x3E7d1eAB13ad0104d2750B8863b489D65364e32D",
      [AVAX_ID]: "0xEBE676ee90Fe1112671f19b6B7459bC678B67e8a",
      [POLYGON_ID]: "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
    },
    address: {
      [ARB_ID]: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      [ETH_ID]: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      [AVAX_ID]: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
      [POLYGON_ID]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    },
  },
  USDC: {
    symbol: "USDC",
    img: USDC_img,
    title: "USD Coin",
    chainlink: {
      [ARB_ID]: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
      [ETH_ID]: "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6",
      [AVAX_ID]: "0xF096872672F44d6EBA71458D74fe67F9a77a23B9",
      [POLYGON_ID]: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7",
    },
    address: {
      [ARB_ID]: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      [ETH_ID]: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      [AVAX_ID]: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      [POLYGON_ID]: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    },
  },
  "USDC.e": {
    symbol: "USDC.e",
    img: USDC_img,
    title: "Bridged USDC",
    chainlink: {
      [ARB_ID]: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
      [ETH_ID]: "",
      [AVAX_ID]: "0xF096872672F44d6EBA71458D74fe67F9a77a23B9",
      [POLYGON_ID]: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7",
    },
    address: {
      [ARB_ID]: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      [ETH_ID]: "",
      [AVAX_ID]: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      [POLYGON_ID]: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    },
  },
  DAI: {
    symbol: "DAI",
    img: DAI_img,
    title: "Dai",
    chainlink: {
      [ARB_ID]: "0xc5C8E77B397E531B8EC06BFb0048328B30E9eCfB",
      [ETH_ID]: "0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9",
      [AVAX_ID]: "0x51D7180edA2260cc4F6e4EebB82FEF5c3c2B8300",
      [POLYGON_ID]: "0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D",
    },
    address: {
      [ARB_ID]: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      [ETH_ID]: "0x6b175474e89094c44da98b954eedeac495271d0f",
      [AVAX_ID]: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
      [POLYGON_ID]: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    },
  },
  WBTC: {
    symbol: "WBTC",
    img: WBTC_img,
    title: "Wrapped Bitcoin",
    chainlink: {
      [ARB_ID]: "0xd0C7101eACbB49F3deCcCc166d238410D6D46d57",
      [ETH_ID]: "0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c",
      [AVAX_ID]: "",
      [POLYGON_ID]: "0xDE31F8bFBD8c84b5360CFACCa3539B938dd78ae6",
    },
    address: {
      [ARB_ID]: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      [ETH_ID]: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      [AVAX_ID]: "",
      [POLYGON_ID]: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    },
  },
  WETH: {
    symbol: "WETH",
    img: WETH_img,
    title: "Wrapped ETH",
    chainlink: {
      [ARB_ID]: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
      [ETH_ID]: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
      [AVAX_ID]: "",
      [POLYGON_ID]: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
    },
    address: {
      [ETH_ID]: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      [ARB_ID]: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      [AVAX_ID]: "",
      [POLYGON_ID]: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    },
  },
  LINK: {
    symbol: "LINK",
    img: LINK_img,
    title: "Chainlink",
    chainlink: {
      [ARB_ID]: "0x86E53CF1B870786351Da77A57575e79CB55812CB",
      [ETH_ID]: "0x2c1d072e956AFFC0D435Cb7AC38EF18d24d9127c",
      [AVAX_ID]: "0x49ccd9ca821EfEab2b98c60dC60F518E765EDe9a",
      [POLYGON_ID]: "0xd9FFdb71EbE7496cC440152d43986Aae0AB76665",
    },
    address: {
      [ARB_ID]: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      [ETH_ID]: "0x514910771af9ca656af840dff83e8264ecf986ca",
      [AVAX_ID]: "0x5947bb275c521040051d82396192181b413227a3",
      [POLYGON_ID]: "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    },
  },
  UNI: {
    symbol: "UNI",
    img: UNI_img,
    title: "Uniswap",
    chainlink: {
      [ARB_ID]: "0x9C917083fDb403ab5ADbEC26Ee294f6EcAda2720",
      [ETH_ID]: "0x553303d460EE0afB37EdFf9bE42922D8FF63220e",
      [AVAX_ID]: "0x9a1372f9b1B71B3A5a72E092AE67E172dBd7Daaa",
      [POLYGON_ID]: "0xdf0Fb4e4F928d2dCB76f438575fDD8682386e13C",
    },
    address: {
      [ARB_ID]: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      [ETH_ID]: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
      [AVAX_ID]: "0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580",
      [POLYGON_ID]: "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    },
  },
  WMATIC: {
    symbol: "WMATIC",
    img: WMATIC_img,
    title: "Wrapped Matic",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "",
      [POLYGON_ID]: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "",
      [POLYGON_ID]: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    },
  },
  TUSD: {
    symbol: "TUSD",
    img: TUSD_img,
    title: "TrueUSD",
    chainlink: {
      [ARB_ID]: "0x6fAbee62266Da6686EE2744C6f15bb8352d2f28D",
      [ETH_ID]: "0xec746eCF986E2927Abd291a2A1716c940100f8Ba",
      [AVAX_ID]: "0x9Cf3Ef104A973b351B2c032AA6793c3A6F76b448",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07",
      [ETH_ID]: "0x0000000000085d4780B73119b644AE5ecd22b376",
      [AVAX_ID]: "0x1c20e891bab6b1727d14da358fae2984ed9b59eb",
      [POLYGON_ID]: "",
    },
  },
  LDO: {
    symbol: "LDO",
    img: LDO_img,
    title: "Lido DAO",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
  },
  ARB: {
    symbol: "ARB",
    img: ARB_img,
    title: "Arbitrum",
    chainlink: {
      [ARB_ID]: "0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6",
      [ETH_ID]: "",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      [ETH_ID]: "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
  },
  GRT: {
    symbol: "GRT",
    img: GRT_img,
    title: "The Graph",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "0x86cF33a451dE9dc61a2862FD94FF4ad4Bd65A5d2",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "0x9623063377AD1B27544C965cCd7342f7EA7e88C7",
      [ETH_ID]: "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
  },
  FRAX: {
    symbol: "FRAX",
    img: FRAX_img,
    title: "Frax",
    chainlink: {
      [ARB_ID]: "0x0809E3d38d1B4214958faf06D8b1B1a2b73f2ab8",
      [ETH_ID]: "0xB9E1E3A9feFf48998E45Fa90847ed4D467E8BcfD",
      [AVAX_ID]: "0xbBa56eF1565354217a3353a466edB82E8F25b08e",
      [POLYGON_ID]: "0x00DBeB1e45485d53DF7C2F0dF1Aa0b6Dc30311d3",
    },
    address: {
      [ARB_ID]: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      [ETH_ID]: "0x853d955acef822db058eb8505911ed77f175b99e",
      [AVAX_ID]: "0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64",
      [POLYGON_ID]: "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
    },
  },
  MKR: {
    symbol: "MKR",
    img: MKR_img,
    title: "Maker",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "0xec1D1B3b0443256cc3860e24a46F108e699484Aa",
      [AVAX_ID]: "0x3E54eB0475051401D093702A5DB84EFa1Ab77b14",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
      [AVAX_ID]: "0x88128fd4b259552A9A1D457f435a6527AAb72d42",
      [POLYGON_ID]: "",
    },
  },
  USDD: {
    symbol: "USDD",
    img: USDD_img,
    title: "USDD",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "0x0ed39A19D2a68b722408d84e4d970827f61E6c0A",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
  },
  CRV: {
    symbol: "CRV",
    img: CRV_img,
    title: "Curve DAO Token",
    chainlink: {
      [ARB_ID]: "0xaebDA2c976cfd1eE1977Eac079B4382acb849325",
      [ETH_ID]: "0xCd627aA160A6fA45Eb793D19Ef54f5062F20f33f",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978",
      [ETH_ID]: "0xD533a949740bb3306d119CC777fa900bA034cd52",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
  },
  COMP: {
    symbol: "COMP",
    img: COMP_img,
    title: "Compound",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "0xdbd020CAeF83eFd542f4De03e3cF0C28A4428bd5",
      [AVAX_ID]: "0x9D6AA0AC8c4818433bEA7a74F49C73B57BcEC4Ec",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "0xc00e94cb662c3520282e6f5717214004a7f26888",
      [AVAX_ID]: "0xc3048E19E76CB9a3Aa9d77D8C03c29Fc906e2437",
      [POLYGON_ID]: "",
    },
  },
  GMX: {
    symbol: "GMX",
    img: GMX_img,
    title: "GMX",
    chainlink: {
      [ARB_ID]: "0xDB98056FecFff59D032aB628337A4887110df3dB",
      [ETH_ID]: "",
      [AVAX_ID]: "0x3F968A21647d7ca81Fb8A5b69c0A452701d5DCe8",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
      [ETH_ID]: "",
      [AVAX_ID]: "0x62edc0692BD897D2295872a9FFCac5425011c661",
      [POLYGON_ID]: "",
    },
  },
  "BTC.b": {
    symbol: "BTC.b",
    img: BTCB_img,
    title: "Wrapped Bitcoin",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x86442E3a98558357d46E6182F4b262f76c4fa26F",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x408d4cd0adb7cebd1f1a1c33a0ba2098e1295bab",
      [POLYGON_ID]: "",
    },
  },
  "WBTC.e": {
    symbol: "WBTC.e",
    img: WBTC_img,
    title: "Bridged Bitcoin",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x2779D32d5166BAaa2B2b658333bA7e6Ec0C65743",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      [POLYGON_ID]: "",
    },
  },
  "WETH.e": {
    symbol: "WETH.e",
    img: WETH_img,
    title: "Bridged ETH",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x976B3D034E162d8bD72D6b9C989d545b839003b0",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      [POLYGON_ID]: "",
    },
  },
  WAVAX: {
    symbol: "WAVAX",
    img: AVAX_img,
    title: "Wrapped Avalanche",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0x0A77230d17318075983913bC2145DB16C7366156",
      [POLYGON_ID]: "",
    },
    address: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      [POLYGON_ID]: "",
    },
  },
  VKA: {
    symbol: "VKA",
    img: VKA_img,
    title: "Vaultka",
    chainlink: {
      [ARB_ID]: "",
      [ETH_ID]: "",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
    address: {
      [ETH_ID]: "",
      [ARB_ID]: "0xAFccb724e3aec1657fC9514E3e53A0E71e80622D",
      [AVAX_ID]: "",
      [POLYGON_ID]: "",
    },
  },
};
export default TOKEN_LIST;
