import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  styled,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { FiCopy } from "react-icons/fi";
import { get } from "lodash";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import { useSnackbar } from "notistack";
import walletMapping from "../constant/walletMapping";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import { convertChainId } from "../utils/display";
import SsoButton from "./SsoButton";
import { useCopyToClipboard } from "react-use";

interface IModalWalletDetail {
  open: boolean;
  setOpen: Function;
}

const ModalWalletDetail = ({ open, setOpen }: IModalWalletDetail) => {
  const { address, connector } = useAccount();
  const { chain: walletChain } = useNetwork();
  const chainId = useMemo(() => convertChainId(walletChain?.id), [walletChain]);
  const { disconnect } = useDisconnect();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [_, copyToClipboard] = useCopyToClipboard();

  const addrValue = useMemo(
    () => `${get(CHAIN_ID_MAPPING, `${chainId}.short`, "")}:${address}`,
    [address, chainId]
  );

  const onClickDisconnect = useCallback(() => {
    disconnect();
  }, [disconnect]);

  return (
    <StyleLayer
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: { sx: { backgroundColor: "rgba(0,0,0,.2)" } },
      }}
    >
      <Box className="mod-content">
        <Box sx={{ display: "flex", alignItems: "center", mb: "25px" }}>
          <TextField
            variant="outlined"
            fullWidth
            sx={{ input: { p: "8px 5px" } }}
            value={addrValue}
            InputProps={{
              readOnly: true,
            }}
          />
          <Box sx={{ ml: "5px", flexShrink: 0 }}>
            <IconButton
              onClick={() => {
                copyToClipboard(addrValue);
                enqueueSnackbar(t("COPIED"));
              }}
            >
              <FiCopy size={14} />
            </IconButton>
          </Box>
          {get(CHAIN_ID_MAPPING, `${chainId}.scan`) && (
            <Box sx={{ flexShrink: 0 }}>
              <IconButton
                href={
                  address
                    ? `${get(
                        CHAIN_ID_MAPPING,
                        `${chainId}.scan`
                      )}address/${address}`
                    : ""
                }
                target="_blank"
              >
                <img
                  src={get(
                    CHAIN_ID_MAPPING,
                    `${chainId}.scanLogo`,
                    ""
                  ).toString()}
                  alt=""
                  width={15}
                />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className="wd-row-item">
          <span className="short-t">Wallet</span>
          <span>
            {get(walletMapping, `${connector?.name}.title`, "").toString()}
          </span>
        </Box>
        <Box className="wd-row-item">
          <span className="short-t">Connected network</span>
          <span>
            {get(CHAIN_ID_MAPPING, `${chainId}.title`, "").toString()}
          </span>
        </Box>
        <Button
          onClick={onClickDisconnect}
          variant="contained"
          sx={{ margin: "20px auto 0 auto", display: "block" }}
        >
          {t("DISCON_T")}
        </Button>
        <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.5)", my: "30px" }} />
        <SsoButton position="center" />
      </Box>
    </StyleLayer>
  );
};

export default ModalWalletDetail;

const StyleLayer = styled(Modal)(({ theme }) => ({
  ".mod-content": {
    width: "300px",
    minHeight: "auto",

    [theme.breakpoints.up("md")]: {
      top: "80px",
      left: "auto",
      right: "130px",
      transform: "none",
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  ".wd-row-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0",
    ".short-t": {
      fontSize: "14px",
    },
  },
}));
