import { atom } from "recoil";

interface IRenameAddress {
  address: string;
  id?: string;
  nickName?: string;
}

const renameAddressModalState = atom<IRenameAddress | false>({
  key: "renameAddressModalState",
  default: false,
});

export default renameAddressModalState;
