import React, { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Web3Modal } from "@web3modal/react";
import Moralis from "moralis";
import { CredentialResponse, useGoogleOneTapLogin } from "@react-oauth/google";
import AppRoute from "./AppRoute";
import ModalConnectWallet from "../component/ModalConnectWallet";
import ModalQrCode from "../component/ModalQrCode";
import ModalLoading from "../component/ModalLoading";
import ModalConfirm from "../component/ModalConfirm";
import { ethereumClient } from "../constant/wagmiState";
import chainState, { DEFAULT_CHAIN } from "../atom/chainState";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import { CHAIN_STORE } from "../constant/localStore";
import pj from "../../package.json";
import ModalRenameAddress from "../component/ModalRenameAddress";
import ssoUserState from "../atom/ssoUserState";
import useSsoUser from "../hooks/useSsoUser";
import useAddressMapping from "../hooks/useAddressMapping";

const AppMain = () => {
  const [chain, setChain] = useRecoilState(chainState);
  const [ssoUser] = useRecoilState(ssoUserState);
  const { onSuccessLoginGoogle } = useSsoUser();
  const { onFetchAddress } = useAddressMapping();

  // get chain by local data
  useEffect(() => {
    const localStorageFetch = () => {
      const chainStore = localStorage.getItem(CHAIN_STORE) || DEFAULT_CHAIN;
      if (!chain && chainStore) {
        setChain(chainStore);
      }
    };
    localStorageFetch();
  }, [chain, setChain]);

  useEffect(() => {
    console.log("KnightSafe interface", pj.version);
    const initMoralis = async () => {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS_KEY,
      });
    };
    initMoralis();
  }, []);

  useGoogleOneTapLogin({
    onSuccess: onSuccessLoginGoogle,
    onError: () => {
      console.log("Login Failed");
    },
    auto_select: true,
  });

  useEffect(() => {
    onFetchAddress();
  }, [onFetchAddress]);

  return (
    <div className="App">
      <AppRoute />
      <ModalConnectWallet />
      <ModalQrCode />
      <ModalLoading />
      <ModalRenameAddress />
      <Web3Modal
        projectId={process.env.REACT_APP_WALLET_CONNECT_ID || ""}
        ethereumClient={ethereumClient}
        defaultChain={CHAIN_ID_MAPPING[chain]?.wagmiChain}
        themeMode="dark"
        themeVariables={{
          "--w3m-z-index": "1301",
        }}
      />
    </div>
  );
};

export default AppMain;
