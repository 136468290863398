import { ReactNode } from "react";
import { atom } from "recoil";

interface IConfirmModalState {
  size?: "sm" | "md";
  message?: ReactNode;
  yesText?: string;
  noText?: string;
  no?: Function;
  yes?: Function;
  title?: string;
}

const confirmModalState = atom<IConfirmModalState | false>({
  key: "confirmModalState",
  default: false,
});

export default confirmModalState;
