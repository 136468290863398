import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { WagmiConfig } from "wagmi";
import { RecoilRoot } from "recoil";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "../utils/i18n";
import AppMain from "./AppMain";
import { wagmiConfig } from "../constant/wagmiState";
import { breakpoints } from "../constant/bp";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8061FF",
    },
  },
  breakpoints: breakpoints,
});

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}
    >
      <RecoilRoot>
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <WagmiConfig config={wagmiConfig}>
              <AppMain />
            </WagmiConfig>
          </ThemeProvider>
        </SnackbarProvider>
      </RecoilRoot>
    </GoogleOAuthProvider>
  );
}

export default App;
