import { arbitrum, mainnet, avalanche, polygon, Chain } from "wagmi/chains";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { ARB_RPC, AVAX_RPC, ETH_RPC, POLYGON_RPC } from "./rpc";
import {
  ARB_FACTORY_ADDR,
  ARB_MASTER_ADDR,
  ARB_MASTER_ADDR_WHITE_LIST,
  ETH_FACTORY_ADDR,
  ETH_MASTER_ADDR,
  ETH_MASTER_ADDR_WHITE_LIST,
  AVAX_FACTORY_ADDR,
  AVAX_MASTER_ADDR,
  AVAX_MASTER_ADDR_WHITE_LIST,
  POLYGON_FACTORY_ADDR,
  POLYGON_MASTER_ADDR,
  POLYGON_MASTER_ADDR_WHITE_LIST,
} from "./address";
import arbScanLogo from "../assets/images/arbiscan.png";
import ethScanLogo from "../assets/images/etherscan.png";
import avaxScanLogo from "../assets/images/avaxscan.svg";
import polygonScanLogo from "../assets/images/polygonscan.svg";
import {
  AVAX_APP_FUNCTION_WHITELIST_MAP,
  AVAX_TOKEN_WHITELIST_MAP,
} from "./functions/AVAX_FUNCTION_MAPPING";
import {
  ARB_APP_FUNCTION_WHITELIST_MAP,
  ARB_APP_FUNCTION_WHITELIST_MAP_OUTDATE,
  ARB_TOKEN_WHITELIST_MAP,
} from "./functions/ARB_FUNCTION_MAPPING";
import {
  ETH_APP_FUNCTION_WHITELIST_MAP,
  ETH_TOKEN_WHITELIST_MAP,
} from "./functions/ETH_FUNCTION_MAPPING";
import {
  POLYGON_APP_FUNCTION_WHITELIST_MAP,
  POLYGON_TOKEN_WHITELIST_MAP,
} from "./functions/POLYGON_FUNCTION_MAPPING";
import {
  IWhitelistContractMap,
  IWhitelistFunctionMap,
} from "./functions/types";

export const ARB_ID = "0xa4b1";
export const ETH_ID = "0x1";
export const AVAX_ID = "0xa86a";
export const POLYGON_ID = "0x89";

interface IChainId {
  title: string;
  short: string;
  rpc: string;
  scan: string;
  scanLogo: string;
  tokenMapping: IWhitelistContractMap;
  functionMapping: IWhitelistFunctionMap;
  functionMappingOutdate?: IWhitelistFunctionMap[];
  masterAddr: string;
  masterAddrWhiteList: string[];
  factoryAddr: string;
  debank: string;
  gnosisApi: string;
  wagmiChain: Chain;
  moralisNetwork: EvmChain;
}

export interface ICHAIN_ID_MAPPING {
  [n: string]: IChainId;
}

export const CHAIN_ID_MAPPING: ICHAIN_ID_MAPPING = {
  [ARB_ID]: {
    title: "Arbitrum",
    short: "arb1",
    rpc: ARB_RPC,
    scan: "https://arbiscan.io/",
    scanLogo: arbScanLogo,
    tokenMapping: ARB_TOKEN_WHITELIST_MAP,
    functionMapping: ARB_APP_FUNCTION_WHITELIST_MAP,
    functionMappingOutdate: ARB_APP_FUNCTION_WHITELIST_MAP_OUTDATE,
    masterAddr: ARB_MASTER_ADDR,
    masterAddrWhiteList: ARB_MASTER_ADDR_WHITE_LIST,
    factoryAddr: ARB_FACTORY_ADDR,
    debank: "https://debank.com/profile/",
    gnosisApi: "https://safe-transaction-arbitrum.safe.global/",
    wagmiChain: arbitrum,
    moralisNetwork: EvmChain.ARBITRUM,
  },
  [ETH_ID]: {
    title: "Ethereum",
    short: "eth",
    rpc: ETH_RPC,
    scan: "https://etherscan.io/",
    scanLogo: ethScanLogo,
    tokenMapping: ETH_TOKEN_WHITELIST_MAP,
    functionMapping: ETH_APP_FUNCTION_WHITELIST_MAP,
    masterAddr: ETH_MASTER_ADDR,
    masterAddrWhiteList: ETH_MASTER_ADDR_WHITE_LIST,
    factoryAddr: ETH_FACTORY_ADDR,
    debank: "https://debank.com/profile/",
    gnosisApi: "https://safe-transaction-mainnet.safe.global/",
    wagmiChain: mainnet,
    moralisNetwork: EvmChain.ETHEREUM,
  },
  [AVAX_ID]: {
    title: "Avalanche",
    short: "avax",
    rpc: AVAX_RPC,
    scan: "https://snowtrace.io/",
    scanLogo: avaxScanLogo,
    tokenMapping: AVAX_TOKEN_WHITELIST_MAP,
    functionMapping: AVAX_APP_FUNCTION_WHITELIST_MAP,
    masterAddr: AVAX_MASTER_ADDR,
    masterAddrWhiteList: AVAX_MASTER_ADDR_WHITE_LIST,
    factoryAddr: AVAX_FACTORY_ADDR,
    debank: "https://debank.com/profile/",
    gnosisApi: "https://safe-transaction-mainnet.safe.global/",
    wagmiChain: avalanche,
    moralisNetwork: EvmChain.AVALANCHE,
  },
  [POLYGON_ID]: {
    title: "Polygon",
    short: "matic",
    rpc: POLYGON_RPC,
    scan: "https://polygonscan.com/",
    scanLogo: polygonScanLogo,
    tokenMapping: POLYGON_TOKEN_WHITELIST_MAP,
    functionMapping: POLYGON_APP_FUNCTION_WHITELIST_MAP,
    masterAddr: POLYGON_MASTER_ADDR,
    masterAddrWhiteList: POLYGON_MASTER_ADDR_WHITE_LIST,
    factoryAddr: POLYGON_FACTORY_ADDR,
    debank: "https://debank.com/profile/",
    gnosisApi: "https://safe-transaction-mainnet.safe.global/",
    wagmiChain: polygon,
    moralisNetwork: EvmChain.POLYGON,
  },
};

export default CHAIN_ID_MAPPING;
