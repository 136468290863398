export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export const minDesktop: string = `(min-width: ${breakpoints.values.md}px)`; // same as mui md
export const maxDesktop: string = `(max-width: ${breakpoints.values.md - 1}px)`;
export const minLg: string = `(min-width: ${breakpoints.values.lg}px)`; // same as mui lg
export const maxLg: string = `(max-width: ${breakpoints.values.lg - 1}px)`;
