import React from "react";
import { Box, CircularProgress, Modal, styled } from "@mui/material";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import isLoadingModState from "../atom/isLoadingModState";

const ModalLoading = () => {
  const [open] = useRecoilState(isLoadingModState);
  const { t } = useTranslation();

  return (
    <StyleLayer open={open}>
      <Box className="mod-content">
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
          <p>{t("LOADING_T")}</p>
        </Box>
      </Box>
    </StyleLayer>
  );
};

export default ModalLoading;

const StyleLayer = styled(Modal)({
  ".mod-content": { width: "160px", minHeight: "auto", padding: "30px 30px" },
  ".wal-list": {
    marginTop: "15px",
    button: {
      width: "100%",
      textAlign: "left",
      textTransform: "none",
      paddingTop: "8px",
      paddingBottom: "8px",
      img: { width: "30px", display: "inline-block", marginRight: "10px" },
    },
  },
});
