import React, { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { get, isEmpty } from "lodash";
import { Box, Button, Typography, styled } from "@mui/material";
import { useMedia } from "react-use";
import { useTranslation } from "react-i18next";
import { isAddress } from "web3-validator";
import { enqueueSnackbar } from "notistack";
import MainHeader from "../component/MainHeader";
import chainState, { DEFAULT_CHAIN } from "../atom/chainState";
import LeftMetaData from "../component/LeftMetaData";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import PageMenu from "../component/PageMenu";
import MobileMenu from "../component/MobileMenu";
import { minDesktop } from "../constant/bp";
import RefreshProfileButton from "../component/RefreshProfileButton";
import profileState from "../atom/profileState";
import isLoadingModState from "../atom/isLoadingModState";
import getKnightSafeProfile from "../utils/getKnightSafeProfile";
import { CHAIN_STORE, setAddressStore } from "../constant/localStore";
import { checkIsKnightSafe } from "../utils/checkIsKnightSafe";
import useLogout from "../hooks/useLogout";
import ModalMyLink from "../component/ModalMyLink";
import useInitWalletConnect from "../hooks/useInitWalletConnect";
import useAddressMapping from "../hooks/useAddressMapping";
import ModalConfirm from "../component/ModalConfirm";

const TIMEOUT_TIME = 1000 * 10;

const Applayout = () => {
  const [chain, setChain] = useRecoilState(chainState);
  const [profile, setProfile] = useRecoilState(profileState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesk = useMedia(minDesktop);
  const [search] = useSearchParams();
  const [loadingOpen, setLoadingOpen] = useRecoilState(isLoadingModState);
  const onLogout = useLogout();
  const { onFetchAddress } = useAddressMapping();
  useInitWalletConnect();

  useEffect(() => {
    const adr = search.get("address");
    const chn = search.get("chain");
    const chainV =
      chn || chain || localStorage.getItem(CHAIN_STORE) || DEFAULT_CHAIN;
    if (!loadingOpen && adr && adr !== profile?.address) {
      const jumpToOnboard = async () => {
        navigate({ pathname: "/onboarding", search: "" });
        setTimeout(() => setLoadingOpen(false), 100);
        // enqueueSnackbar(t("FAIL_FETCH"), {
        //   variant: "",
        // });
      };
      const autoFetchByQuery = async () => {
        setLoadingOpen(true);

        // check valid chain and address
        if (
          !isAddress(adr) ||
          !Object.keys(CHAIN_ID_MAPPING).includes(chainV)
        ) {
          jumpToOnboard();
          return;
        }

        const isKnightSafe = await Promise.race([
          checkIsKnightSafe(adr, chainV),
          new Promise<false>((res) =>
            setTimeout(() => res(false), TIMEOUT_TIME)
          ),
        ]);

        if (!isKnightSafe) {
          await jumpToOnboard();
          return;
        }
        const profileRes = await Promise.race([
          getKnightSafeProfile(adr as `0x${string}`, chainV),
          new Promise<null>((res) => setTimeout(() => res(null), TIMEOUT_TIME)),
        ]);
        if (!isEmpty(profileRes)) {
          setProfile(profileRes);
          enqueueSnackbar(t("EXIST_KS_ADDED"), {
            variant: "success",
          });
          setAddressStore(profileRes.address, chainV);
          // onFetchAddress(adr, chainV);
          if (chainV) {
            setChain(chainV);
            localStorage.setItem(CHAIN_STORE, chainV);
          }
          setLoadingOpen(false);
        } else {
          jumpToOnboard();
        }
      };
      autoFetchByQuery();
    }
  }, [
    chain,
    loadingOpen,
    navigate,
    onFetchAddress,
    profile?.address,
    search,
    setChain,
    setLoadingOpen,
    setProfile,
    t,
  ]);

  return (
    <StyleLayer>
      <MainHeader />
      <div className="layout">
        {isDesk && (
          <div className="layout-left">
            <p className="layout-left-head">
              {get(CHAIN_ID_MAPPING, `${chain}.title`, "").toString()}
            </p>
            <div className="layout-left-main">
              <LeftMetaData />
              <hr />
              <PageMenu />
            </div>
          </div>
        )}
        <div className="layout-right">
          {profile?.address ? (
            <>
              <Box>
                <RefreshProfileButton />
              </Box>
              <Outlet />
            </>
          ) : (
            !loadingOpen && (
              <Box>
                <Typography
                  component="h1"
                  sx={{ fontSize: "22px", mt: "30px", mb: "15px" }}
                >
                  {t("NO_KS_AC")}
                </Typography>
                <Button variant="contained" onClick={onLogout}>
                  {t("GO_MAIN")}
                </Button>
              </Box>
            )
          )}
        </div>
      </div>
      <MobileMenu />
      <ModalMyLink />
      <ModalConfirm />
    </StyleLayer>
  );
};

export default Applayout;

const StyleLayer = styled("div")(({ theme }) => ({
  fontFamily: '"Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  color: "#fff",
  ".layout": {
    display: "flex",
    minHeight: "calc(100vh - 80px)",
  },
  ".layout-left": {
    width: "320px",
    backgroundColor: "#32344a",
    padding: "0",
  },
  ".layout-right": {
    flex: 1,
    background: [
      "rgb(57, 48, 99)",
      "linear-gradient( 262deg, rgba(57, 48, 99, 1) 0%, rgba(32, 33, 36, 1) 100%)",
    ],
    padding: "20px 30px 80px 30px",
    maxWidth: "calc(100% - 320px)",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      maxWidth: "100%",
    },
  },
  ".layout-left-head": {
    textAlign: "center",
    backgroundColor: "#8061ff",
    color: "#fff",
    padding: "10px",
  },
  ".layout-left-main": {
    padding: "15px 30px",
    hr: {
      margin: "20px 0px",
      borderColor: "rgba(255, 255, 255, 0.5)",
      "&.mt-10": {
        marginTop: "130px",
      },
      "&.mt-6": {
        marginTop: "50px",
      },
    },
  },
  ".MuiChip-root": {
    height: "20px",
    borderRadius: "5px",
    marginTop: "5px",
  },
  ".MuiChip-label": {
    paddingLeft: "18px",
    paddingRight: "18px",
  },
  ".MuiButton-outline": {},
  ".MuiSwitch-root .MuiSwitch-track": {
    backgroundColor: "#979797",
  },
  ".btns": {
    display: "flex",
    padding: "10px 0",
    "> button, > a": {
      margin: "0 10px 0 0",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      minWidth: "auto",
      fontSize: "20px",
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
      },
    },
  },
}));
