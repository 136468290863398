import { Box } from "@mui/material";
import QRCode from "react-qr-code";

interface IQrCodeBox {
  value: string;
}

const QrCodeBox = ({ value }: IQrCodeBox) => (
  <Box sx={{ textAlign: "center" }}>
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        p: "6px",
        my: "10px",
        background: "#fff",
      }}
    >
      <QRCode value={value} size={200} />
    </Box>
  </Box>
);

export default QrCodeBox;
