import React, { useState } from "react";
import { Box, Button, SxProps, Typography } from "@mui/material";
import moment from "moment";
import { useInterval } from "react-use";
import { MdRefresh } from "react-icons/md";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import profileState from "../atom/profileState";
import useRefreshProfile from "../hooks/useRefreshProfile";

interface IRefreshProfileButton {
  sx?: SxProps;
}

const RefreshProfileButton = ({ sx }: IRefreshProfileButton) => {
  const refresh = useRefreshProfile();
  const [profile] = useRecoilState(profileState);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [fromNow, setFromNow] = useState(() =>
    profile?.lastUpdateTime ? moment(profile.lastUpdateTime).fromNow() : null
  );

  useInterval(() => {
    setFromNow(moment(profile?.lastUpdateTime).fromNow());
  }, 10000);

  return (
    <StyleLayer
      sx={{
        marginBottom: "-30px",
        marginTop: "-10px",
        ...sx,
      }}
    >
      <Button
        size="small"
        onClick={async () => {
          await refresh(true);
          enqueueSnackbar(t("PROF_REF"), {
            variant: "success",
          });
        }}
        sx={{
          color: "#fff",
          ml: "auto",
          display: "flex",
        }}
      >
        <MdRefresh color="#fff" size={18} style={{ marginRight: "6px" }} />
        {t("REF_T")}
      </Button>
      {profile?.lastUpdateTime && (
        <Typography component="p" className="last-time">
          {fromNow}
        </Typography>
      )}
    </StyleLayer>
  );
};

export default RefreshProfileButton;

const StyleLayer = styled(Box)({
  textAlign: "right",
  ".last-time": {
    fontSize: "12px",
    lineHeight: 1,
    marginTop: "-5px",
    padding: "0 5px",
    color: "#bdbdbd",
  },
});
