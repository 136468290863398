import React from "react";
import { get, isArray, isNil, chain } from "lodash";
import numbro from "numbro";
import { Tooltip } from "@mui/material";
import { toChecksumAddress } from "web3-utils";
import { IAppFuncBool } from "../constant/APP_LIST";
import TOKEN_LIST, { IToken } from "../constant/TOKEN_LIST";
import { ERROR_MESSAGE, IErrorMessage } from "../constant/ERROR_MESSAGE";
import iText from "../lang/en.json";
import { isAddress } from "web3-validator";

export const minAddr = (ac: string | undefined, f = 5, b = 4) => {
  if (isNil(ac)) {
    return "";
  }
  const len = ac.length;
  return `${ac.substring(0, f)}...${ac.substring(len - b, len)}`;
};

export const compareAddr = (
  addr1: string | undefined,
  addr2: string | undefined
) => {
  if (!addr1 || !addr2 || !isAddress(addr1) || !isAddress(addr2)) {
    return false;
  }
  return toChecksumAddress(addr1) === toChecksumAddress(addr2);
};

export const getPriceToUsD = (
  balance: string | number | undefined,
  rate: number | undefined
) => {
  if (isNil(balance) || isNil(rate) || isNaN(rate)) {
    return 0;
  }
  return Number(balance) * rate;
};

export const displayPrice = (
  balance: string | number | undefined,
  rate?: number | undefined
) => {
  const am = getPriceToUsD(balance, rate);
  if (am < 0.01 && am > 0) {
    return "< 0.01";
  }
  return numbro(am).format({ thousandSeparated: true, mantissa: 2 });
};

export const displayBalance = (balance: string | undefined) =>
  !isNil(balance)
    ? numbro(balance).format({ thousandSeparated: false, mantissa: 8 })
    : "";

export const convertTokenAddr = (
  tokens: string[] | undefined,
  chainV: string | undefined
): IAppFuncBool | undefined => {
  if (!isArray(tokens) || isNil(chainV)) {
    return;
  }
  const afb = chain(TOKEN_LIST)
    .filter((tp: IToken) => !!get(tp, `address.${chainV}`))
    .map((p) => p.symbol)
    .keyBy((e) => e)
    .mapValues(() => false)
    .value();
  tokens.forEach((e) => (afb[e] = true));
  return afb;
};

export const getErrMsg = (
  name: string,
  er: any,
  options: {
    defaultMessage?: string;
    errorMapper?: IErrorMessage;
    txHash?: string;
  } = {}
): JSX.Element => {
  const e = JSON.parse(JSON.stringify(er));
  const opts = {
    defaultMessage: iText.SOME_ISSUE,
    errorMapper: ERROR_MESSAGE,
    ...options,
  };
  let msg;
  const txt = name + " failed";
  let code;
  if (e.shortMessage) {
    msg = e.shortMessage;
    console.log("getErrMsg ~ shortMessage:", msg);
  } else if (get(e, "innerError.message")) {
    code = get(e, "innerError.message").split(": ")[1] || "";
    console.log("getErrMsg ~ code:", code);
    msg = opts.errorMapper[code];
  } else if (er.message) {
    msg = er.message;
    console.log("getErrMsg ~ message:", msg);
  }
  const tx = e?.txHash || options?.txHash;
  return (
    <p>
      {`${txt}: ${msg || opts.defaultMessage}`}
      {tx && (
        <a
          href={"https://arbiscan.io/tx/" + tx}
          target="_blank"
          rel="noreferrer"
          className="alert-link"
        >
          Details
        </a>
      )}

      {code && (
        <Tooltip title={`${iText.DETAILS_T}: ${code}`} placement="top-start">
          <span className="alert-link">{iText.DETAILS_T}</span>
        </Tooltip>
      )}
    </p>
  );
};

export const delay = (sec: number) =>
  new Promise((r) => setTimeout(r, sec * 1000));

export const convertChainId = (id: number | undefined) => {
  if (isNil(id)) {
    return null;
  }
  return `0x${id.toString(16)}`.toLowerCase();
};

export const getStringBetweenWords = (text: string, w1: string, w2: string) => {
  const startIndex = text.indexOf(w1);
  if (startIndex === -1) {
    return "";
  }
  const endIndex = text.indexOf(w2, startIndex + w1.length);
  if (endIndex === -1) {
    return "";
  }

  return text.substring(startIndex + w1.length, endIndex);
};
