import { IWhitelistContractMap, IWhitelistFunctionMap } from "./types";
import { WhitelistContract, getCheckerParam } from "./utils";

const POLYGON_AAVE_LEND_CHECKER_ADDRESS: string =
  "0x76d16fE50ed31EC7f2B7450E11308E327a3528Cb";
const POLYGON_UNISWAP_SWAP_CHECKER_ADDRESS: string =
  "0x5410494BB41A1e8f24c4D79aFBC5838e86d37B66";

const POLYGON_AAVE_LEND_CHECKER_PARAM = getCheckerParam(
  POLYGON_AAVE_LEND_CHECKER_ADDRESS
);
const POLYGON_UNISWAP_SWAP_CHECKER_PARAM = getCheckerParam(
  POLYGON_UNISWAP_SWAP_CHECKER_ADDRESS
);

export const POLYGON_TOKEN_WHITELIST_MAP: IWhitelistContractMap = {
  USDT: WhitelistContract("0xc2132D05D31c914a87C6611C10748AEb04B58e8F", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    // "0x5353a2d8": [0], // changeName(name_:string)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x3408e470": [0], // getChainId()
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0xde7ea79d": [4], // initialize(name_:string,symbol_:string,decimals_:uint8,childChainManager:address)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    "0x2e1a7d4d": [0], // withdraw(amount:uint256)
  }),
  USDC: WhitelistContract("0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359", {
    "0x095ea7b3": [1], // approve(spender:address,value:uint256)
    // "0xf9f92be4": [1], // blacklist(_account:address)
    // "0x42966c68": [0], // burn(_amount:uint256)
    // "0x5a049a70": [1], // cancelAuthorization(authorizer:address,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0x4e44d956": [1], // configureMinter(minter:address,minterAllowedAmount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,decrement:uint256)
    "0x39509351": [1], // increaseAllowance(spender:address,increment:uint256)
    // "0x3357162b": [5, 6, 7, 8], // initialize(tokenName:string,tokenSymbol:string,tokenCurrency:string,tokenDecimals:uint8,newMasterMinter:address,newPauser:address,newBlacklister:address,newOwner:address)
    // "0xd608ea64": [0], // initializeV2(newName:string)
    // "0x2fc81e09": [1], // initializeV2_1(lostAndFound:address)
    // "0x40c10f19": [1], // mint(_to:address,_amount:uint256)
    // "0x8456cb59": [0], // pause()
    "0xd505accf": [1, 2], // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
    // "0xef55bec6": [1, 2], // receiveWithAuthorization(from:address,to:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0x3092afd5": [1], // removeMinter(minter:address)
    // "0xb2118a8d": [1, 2], // rescueERC20(tokenContract:address,to:address,amount:uint256)
    "0xa9059cbb": [1], // transfer(to:address,value:uint256)
    "0x23b872dd": [1, 2], // transferFrom(from:address,to:address,value:uint256)
    // "0xf2fde38b": [1], // transferOwnership(newOwner:address)
    "0xe3ee160e": [1, 2], // transferWithAuthorization(from:address,to:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0x1a895266": [1], // unBlacklist(_account:address)
    // "0x3f4ba83a": [0], // unpause()
    // "0xad38bf22": [1], // updateBlacklister(_newBlacklister:address)
    // "0xaa20e1e4": [1], // updateMasterMinter(_newMasterMinter:address)
    // "0x554bab3c": [1], // updatePauser(_newPauser:address)
    // "0x2ab60045": [1], // updateRescuer(newRescuer:address)
  }),
  "USDC.e": WhitelistContract("0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    // "0xe1560fd3": [1, 2], // approveWithAuthorization(owner:address,spender:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0xf9f92be4": [1], // blacklist(account:address)
    // "0x5a049a70": [1], // cancelAuthorization(authorizer:address,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    // "0xb0086095": [1, 2], // decreaseAllowanceWithAuthorization(owner:address,spender:address,decrement:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    // "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0xc237108f": [1, 2], // increaseAllowanceWithAuthorization(owner:address,spender:address,increment:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0xde7ea79d": [4], // initialize(newName:string,newSymbol:string,newDecimals:uint8,childChainManager:address)
    // "0x8456cb59": [0], // pause()
    // "0xd505accf": [1, 2], // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xb2118a8d": [1, 2], // rescueERC20(tokenContract:address,to:address,amount:uint256)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    // "0xe3ee160e": [1, 2], // transferWithAuthorization(from:address,to:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    // "0x1a895266": [1], // unBlacklist(account:address)
    // "0x3f4ba83a": [0], // unpause()
    // "0x56ce0e6c": [0], // updateMetadata(newName:string,newSymbol:string)
    // "0x2e1a7d4d": [0], // withdraw(amount:uint256)
    // "0x7b766e5f": [1], // withdrawWithAuthorization(owner:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
  }),
  DAI: WhitelistContract("0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    // "0x5353a2d8": [0], // changeName(name_:string)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x3408e470": [0], // getChainId()
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0xde7ea79d": [4], // initialize(name_:string,symbol_:string,decimals_:uint8,childChainManager:address)
    // "0xbb35783b": [1, 2], // move(src:address,dst:address,wad:uint256)
    "0x8fcbaf0c": [1, 2], // permit(holder:address,spender:address,nonce:uint256,expiry:uint256,allowed:bool,v:uint8,r:bytes32,s:bytes32)
    // "0xf2d5d56b": [1], // pull(usr:address,wad:uint256)
    // "0xb753a98c": [1], // push(usr:address,wad:uint256)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    "0x2e1a7d4d": [0], // withdraw(amount:uint256)
  }),
  WBTC: WhitelistContract("0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    // "0x5353a2d8": [0], // changeName(name_:string)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x3408e470": [0], // getChainId()
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0xde7ea79d": [4], // initialize(name_:string,symbol_:string,decimals_:uint8,childChainManager:address)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    "0x2e1a7d4d": [0], // withdraw(amount:uint256)
  }),
  WETH: WhitelistContract("0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x3408e470": [0], // getChainId()
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    "0x2e1a7d4d": [0], // withdraw(amount:uint256)
  }),
  LINK: WhitelistContract("0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x3408e470": [0], // getChainId()
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    "0x2e1a7d4d": [0], // withdraw(amount:uint256)
  }),
  UNI: WhitelistContract("0xb33EaAd8d922B1083446DC23f610c2567fB5180f", {
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0xcf2c52cb": [1], // deposit(user:address,depositData:bytes)
    "0x0c53c51c": [1], // executeMetaTransaction(userAddress:address,functionSignature:bytes,sigR:bytes32,sigS:bytes32,sigV:uint8)
    // "0x3408e470": [0], // getChainId()
    // "0x2f2ff15d": [2], // grantRole(role:bytes32,account:address)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0xde7ea79d": [4], // initialize(name_:string,symbol_:string,decimals_:uint8,childChainManager:address)
    // "0x36568abe": [2], // renounceRole(role:bytes32,account:address)
    // "0xd547741f": [2], // revokeRole(role:bytes32,account:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    "0x2e1a7d4d": [0], // withdraw(amount:uint256)
  }),
  WMATIC: WhitelistContract("0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", {
    "0x095ea7b3": [1], // approve(guy:address,wad:uint256)
    "0x23b872dd": [1, 2], // transferFrom(src:address,dst:address,wad:uint256)
    "0x2e1a7d4d": [0], // withdraw(wad:uint256)
    "0xa9059cbb": [1], // transfer(dst:address,wad:uint256)
    "0xd0e30db0": [0], // deposit()
  }),
  FRAX: WhitelistContract("0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89", {
    // "0x79ba5097": [0], // acceptOwnership()
    // "0x13489515": [1], // addBridgeToken(bridge_token_address:address)
    // "0x983b2d56": [1], // addMinter(minter_address:address)
    "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
    // "0x42966c68": [0], // burn(amount:uint256)
    // "0x79cc6790": [1], // burnFrom(account:address,amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0x280cf3ed": [1], // exchangeCanonicalForOld(bridge_token_address:address,token_amount:uint256)
    "0x9006a50f": [1], // exchangeOldForCanonical(bridge_token_address:address,token_amount:uint256)
    // "0xb61d27f6": [1], // execute(_to:address,_value:uint256,_data:bytes)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    // "0xf537fe0e": [0], // minter_burn(amount:uint256)
    // "0x6a257ebc": [1], // minter_mint(m_address:address,m_amount:uint256)
    // "0x1627540c": [1], // nominateNewOwner(_owner:address)
    "0xd505accf": [1, 2], // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
    // "0x8980f11f": [1], // recoverERC20(tokenAddress:address,tokenAmount:uint256)
    // "0x3092afd5": [1], // removeMinter(minter_address:address)
    // "0x403f3731": [1], // setCustodian(_custodian_address:address)
    // "0x4070a0c9": [0], // setMintCap(_mint_cap:uint256)
    // "0xa7c571fe": [1], // setSwapFees(bridge_token_address:address,_bridge_to_canonical:uint256,_canonical_to_old:uint256)
    // "0xbdacb303": [1], // setTimelock(new_timelock:address)
    // "0x0d339768": [1], // toggleBridgeToken(bridge_token_address:address)
    // "0x7601f069": [0], // toggleExchanges()
    // "0x0919a951": [1], // toggleFeesForAddress(the_address:address)
    "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    // "0x03978452": [1], // withdrawBridgeTokens(bridge_token_address:address,bridge_token_amount:uint256)
  }),
};

export const POLYGON_APP_FUNCTION_WHITELIST_MAP: IWhitelistFunctionMap = {
  AAVE_LEND: [
    WhitelistContract("0x794a61358D6845594F94dc1DB02A252b5b4814aD", {
      "0xd65dc7a1": [1], // backUnbacked(asset:address,amount:uint256,fee:uint256)
      "0xa415bcad": [1, 5], // borrow(asset:address,amount:uint256,interestRateMode:uint256,referralCode:uint16,onBehalfOf:address)
      // "0xd579ea7d": [], // configureEModeCategory(id:uint8,category:tuple)
      "0xe8eda9df": [1, 3], // deposit(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16)
      "0x63c9b860": [1], // dropReserve(asset:address)
      "0xd5ed3933": [1, 2, 3], // finalizeTransfer(asset:address,from:address,to:address,amount:uint256,balanceFromBefore:uint256,balanceToBefore:uint256)
      // "0xab9c4b5d": [1, 5], // flashLoan(receiverAddress:address,assets:address[],amounts:uint256[],interestRateModes:uint256[],onBehalfOf:address,params:bytes,referralCode:uint16)
      // "0x42b0b77c": [1, 2], // flashLoanSimple(receiverAddress:address,asset:address,amount:uint256,params:bytes,referralCode:uint16)
      // "0x7a708e92": [1, 2, 3, 4, 5], // initReserve(asset:address,aTokenAddress:address,stableDebtAddress:address,variableDebtAddress:address,interestRateStrategyAddress:address)
      // "0xc4d66de8": [1], // initialize(provider:address)
      "0x00a718a9": [1, 2, 3], // liquidationCall(collateralAsset:address,debtAsset:address,user:address,debtToCover:uint256,receiveAToken:bool)
      "0x9cd19996": [POLYGON_AAVE_LEND_CHECKER_PARAM], // mintToTreasury(assets:address[])
      "0x69a933a5": [1, 3], // mintUnbacked(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16)
      "0xcd112382": [1, 2], // rebalanceStableBorrowRate(asset:address,user:address)
      "0x573ade81": [1, 4], // repay(asset:address,amount:uint256,interestRateMode:uint256,onBehalfOf:address)
      "0x2dad97d4": [1], // repayWithATokens(asset:address,amount:uint256,interestRateMode:uint256)
      // "0xee3e210b": [1, 4], // repayWithPermit(asset:address,amount:uint256,interestRateMode:uint256,onBehalfOf:address,deadline:uint256,permitV:uint8,permitR:bytes32,permitS:bytes32)
      // "0xcea9d26f": [1, 2], // rescueTokens(token:address,to:address,amount:uint256)
      "0xe43e88a1": [1], // resetIsolationModeTotalDebt(asset:address)
      // "0xf51e435b": [1, POLYGON_AAVE_LEND_CHECKER_PARAM], // setConfiguration(asset:address,configuration:tuple)
      "0x1d2118f9": [1, 2], // setReserveInterestRateStrategyAddress(asset:address,rateStrategyAddress:address)
      "0x28530a47": [0], // setUserEMode(categoryId:uint8)
      "0x5a3b74b9": [1], // setUserUseReserveAsCollateral(asset:address,useAsCollateral:bool)
      "0x617ba037": [1, 3], // supply(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16)
      // "0x02c205f0": [1, 3], // supplyWithPermit(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16,deadline:uint256,permitV:uint8,permitR:bytes32,permitS:bytes32)
      "0x94ba89a2": [1], // swapBorrowRateMode(asset:address,interestRateMode:uint256)
      "0x3036b439": [0], // updateBridgeProtocolFee(protocolFee:uint256)
      "0xbcb6e522": [0], // updateFlashloanPremiums(flashLoanPremiumTotal:uint128,flashLoanPremiumToProtocol:uint128)
      "0x69328dec": [1, 3], // withdraw(asset:address,amount:uint256,to:address)
    }),
    WhitelistContract("0x1e4b7A6b903680eab0c5dAbcb8fD429cD2a9598c", {
      "0x66514c97": [0], // borrowETH(:address,amount:uint256,interesRateMode:uint256,referralCode:uint16)
      "0x474cf53d": [2], // depositETH(:address,onBehalfOf:address,referralCode:uint16)
      "0xeed88b8d": [1], // emergencyEtherTransfer(to:address,amount:uint256)
      "0xa3d5b255": [1, 2], // emergencyTokenTransfer(token:address,to:address,amount:uint256)
      // "0x715018a6": [0], // renounceOwnership()
      "0x02c5fcf8": [4], // repayETH(:address,amount:uint256,rateMode:uint256,onBehalfOf:address)
      "0xf2fde38b": [1], // transferOwnership(newOwner:address)
      "0x80500d20": [3], // withdrawETH(:address,amount:uint256,to:address)
      "0xd4c40b6c": [3], // withdrawETHWithPermit(:address,amount:uint256,to:address,deadline:uint256,permitV:uint8,permitR:bytes32,permitS:bytes32)
    }),
    WhitelistContract("0xC4aff49fCeD8ac1D818a6DCAB063f9f97E66ec5E", {
      // "0x1b11d0ff": [1, 4], // executeOperation(asset:address,amount:uint256,premium:uint256,initiator:address,params:bytes)
      // "0x715018a6": [0], // renounceOwnership()
      // "0x00ae3bf8": [1], // rescueTokens(token:address)
      "0xd3454a35": [POLYGON_AAVE_LEND_CHECKER_PARAM], // swapAndDeposit(assetToSwapFrom:address,assetToSwapTo:address,amountToSwap:uint256,minAmountToReceive:uint256,swapAllBalanceOffset:uint256,swapCalldata:bytes,augustus:address,permitParams:tuple)
      // "0xf2fde38b": [1], // transferOwnership(newOwner:address)
    }),
    WhitelistContract("0xb58Fd91558fa213D97Ac94C97F831c7289278084", {
      // "0x920f5c84": [4], // executeOperation(assets:address[],amounts:uint256[],:uint256[],initiator:address,params:bytes)
      "0x0a036351": [1], // renewAllowance(reserve:address)
      // "0x715018a6": [0], // renounceOwnership()
      // "0x00ae3bf8": [1], // rescueTokens(token:address)
      "0xb8bd1c6b": [POLYGON_AAVE_LEND_CHECKER_PARAM], // swapDebt(debtSwapParams:tuple,creditDelegationPermit:tuple,collateralATokenPermit:tuple)
      // "0xf2fde38b": [1], // transferOwnership(newOwner:address)
    }),
    WhitelistContract("0xE3090207A2de94A856EA10a7e1Bd36dD6145712B", {
      // "0x1b11d0ff": [1, 4], // executeOperation(asset:address,amount:uint256,premium:uint256,initiator:address,params:bytes)
      // "0x715018a6": [0], // renounceOwnership()
      // "0x00ae3bf8": [1], // rescueTokens(token:address)
      "0x4db9dc97": [POLYGON_AAVE_LEND_CHECKER_PARAM], // swapAndRepay(collateralAsset:address,debtAsset:address,collateralAmount:uint256,debtRepayAmount:uint256,debtRateMode:uint256,buyAllBalanceOffset:uint256,paraswapData:bytes,permitSignature:tuple)
      // "0xf2fde38b": [1], // transferOwnership(newOwner:address)
    }),
    WhitelistContract("0x78F8Bd884C3D738B74B420540659c82f392820e0", {
      "0x1b11d0ff": [1, 4], // executeOperation(:address,:uint256,:uint256,:address,:bytes)
      // "0x715018a6": [0], // renounceOwnership()
      // "0x00ae3bf8": [1], // rescueTokens(token:address)
      // "0xf2fde38b": [1], // transferOwnership(newOwner:address)
      "0x5fd73e07": [1, 2, 7], // withdrawAndSwap(assetToSwapFrom:address,assetToSwapTo:address,amountToSwap:uint256,minAmountToReceive:uint256,swapAllBalanceOffset:uint256,swapCalldata:bytes,augustus:address,permitParams:tuple)
    }),
    WhitelistContract("0x4a1c3aD6Ed28a636ee1751C69071f6be75DEb8B8", {
      "0x095ea7b3": [1], // approve(:address,:uint256)
      "0xc04a8a10": [1], // approveDelegation(delegatee:address,amount:uint256)
      "0xf5298aca": [1], // burn(from:address,amount:uint256,index:uint256)
      "0xa457c2d7": [1], // decreaseAllowance(:address,:uint256)
      "0x0b52d558": [1, 2], // delegationWithSig(delegator:address,delegatee:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
      "0x39509351": [1], // increaseAllowance(:address,:uint256)
      // "0xc222ec8a": [1, 2, 3], // initialize(initializingPool:address,underlyingAsset:address,incentivesController:address,debtTokenDecimals:uint8,debtTokenName:string,debtTokenSymbol:string,params:bytes)
      "0xb3f1c93d": [1, 2], // mint(user:address,onBehalfOf:address,amount:uint256,index:uint256)
      // "0xe655dbd8": [1], // setIncentivesController(controller:address)
      "0xa9059cbb": [1], // transfer(:address,:uint256)
      "0x23b872dd": [1, 2], // transferFrom(:address,:address,:uint256)
    }),
    WhitelistContract("0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", {
      "0x095ea7b3": [1], // approve(guy:address,wad:uint256)
      "0x23b872dd": [1, 2], // transferFrom(src:address,dst:address,wad:uint256)
      "0x2e1a7d4d": [0], // withdraw(wad:uint256)
      "0xa9059cbb": [1], // transfer(dst:address,wad:uint256)
      "0xd0e30db0": [0], // deposit()
    }),
    WhitelistContract("0x4c28f48448720e9000907BC2611F73022fdcE1fA", {
      "0x095ea7b3": [1], // approve(guy:address,wad:uint256)
      "0xd0e30db0": [0], // deposit()
      "0xa9059cbb": [1], // transfer(dst:address,wad:uint256)
      "0x23b872dd": [1, 2], // transferFrom(src:address,dst:address,wad:uint256)
      "0x2e1a7d4d": [0], // withdraw(wad:uint256)
    }),
    WhitelistContract("0x216B4B4Ba9F3e719726886d34a177484278Bfcae", {
      // "0x715018a6": [0], // renounceOwnership()
      "0x15dacbea": [1, 2, 3], // transferFrom(token:address,from:address,to:address,amount:uint256)
      "0xf2fde38b": [1], // transferOwnership(newOwner:address)
    }),
    WhitelistContract("0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97", {
      "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
      // "0xd7020d0a": [1, 2], // burn(from:address,receiverOfUnderlying:address,amount:uint256,index:uint256)
      // "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
      // "0x6fd97676": [1, 2], // handleRepayment(user:address,onBehalfOf:address,amount:uint256)
      // "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
      // "0x183fb413": [1, 2, 3, 4], // initialize(initializingPool:address,treasury:address,underlyingAsset:address,incentivesController:address,aTokenDecimals:uint8,aTokenName:string,aTokenSymbol:string,params:bytes)
      // "0xb3f1c93d": [1, 2], // mint(caller:address,onBehalfOf:address,amount:uint256,index:uint256)
      // "0x7df5bd3b": [0], // mintToTreasury(amount:uint256,index:uint256)
      // "0xd505accf": [1, 2], // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
      // "0xcea9d26f": [1, 2], // rescueTokens(token:address,to:address,amount:uint256)
      // "0xe655dbd8": [1], // setIncentivesController(controller:address)
      // "0xa9059cbb": [1], // transfer(recipient:address,amount:uint256)
      // "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
      // "0xf866c319": [1, 2], // transferOnLiquidation(from:address,to:address,value:uint256)
      // "0x4efecaa5": [1], // transferUnderlyingTo(target:address,amount:uint256)
    }),
    WhitelistContract("0xF15F26710c827DDe8ACBA678682F3Ce24f2Fb56E", {
      "0x095ea7b3": [1], // approve(:address,:uint256)
      // "0xc04a8a10": [1], // approveDelegation(delegatee:address,amount:uint256)
      // "0x9dc29fac": [1], // burn(from:address,amount:uint256)
      // "0xa457c2d7": [1], // decreaseAllowance(:address,:uint256)
      // "0x0b52d558": [1, 2], // delegationWithSig(delegator:address,delegatee:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
      // "0x39509351": [1], // increaseAllowance(:address,:uint256)
      // "0xc222ec8a": [1, 2, 3], // initialize(initializingPool:address,underlyingAsset:address,incentivesController:address,debtTokenDecimals:uint8,debtTokenName:string,debtTokenSymbol:string,params:bytes)
      // "0xb3f1c93d": [1, 2], // mint(user:address,onBehalfOf:address,amount:uint256,rate:uint256)
      // "0xe655dbd8": [1], // setIncentivesController(controller:address)
      // "0xa9059cbb": [1], // transfer(:address,:uint256)
      // "0x23b872dd": [1, 2], // transferFrom(:address,:address,:uint256)
    }),
    WhitelistContract("0x4a1c3aD6Ed28a636ee1751C69071f6be75DEb8B8", {
      "0x095ea7b3": [1], // approve(:address,:uint256)
      // "0xc04a8a10": [1], // approveDelegation(delegatee:address,amount:uint256)
      // "0xf5298aca": [1], // burn(from:address,amount:uint256,index:uint256)
      // "0xa457c2d7": [1], // decreaseAllowance(:address,:uint256)
      // "0x0b52d558": [1, 2], // delegationWithSig(delegator:address,delegatee:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
      // "0x39509351": [1], // increaseAllowance(:address,:uint256)
      // "0xc222ec8a": [1, 2, 3], // initialize(initializingPool:address,underlyingAsset:address,incentivesController:address,debtTokenDecimals:uint8,debtTokenName:string,debtTokenSymbol:string,params:bytes)
      // "0xb3f1c93d": [1, 2], // mint(user:address,onBehalfOf:address,amount:uint256,index:uint256)
      // "0xe655dbd8": [1], // setIncentivesController(controller:address)
      // "0xa9059cbb": [1], // transfer(:address,:uint256)
      // "0x23b872dd": [1, 2], // transferFrom(:address,:address,:uint256)
    }),
  ],
  UNISWAP_SWAP: [
    WhitelistContract("0x643770E279d5D0733F21d6DC03A8efbABf3255B4", {
      // "0x709a1cc2": [0], // collectRewards(looksRareClaim:bytes)
      "0x24856bc3": [POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // execute(commands:bytes,inputs:bytes[])
      "0x3593564c": [POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // execute(commands:bytes,inputs:bytes[],deadline:uint256)
      // "0xbc197c81": [1, 2], // onERC1155BatchReceived(:address,:address,:uint256[],:uint256[],:bytes)
      // "0xf23a6e61": [1, 2], // onERC1155Received(:address,:address,:uint256,:uint256,:bytes)
      // "0x150b7a02": [1, 2], // onERC721Received(:address,:address,:uint256,:bytes)
      // "0x01ffc9a7": [0], // supportsInterface(interfaceId:bytes4)
      // "0xfa461e33": [0], // uniswapV3SwapCallback(amount0Delta:int256,amount1Delta:int256,data:bytes)
    }),
    WhitelistContract("0x000000000022D473030F116dDEE9F6B43aC78BA3", {
      "0x87517c45": [1, 2], // approve(token:address,spender:address,amount:uint160,expiration:uint48)
      // "0x65d9723c": [1, 2], // invalidateNonces(token:address,spender:address,newNonce:uint48)
      // "0x3ff9dcb1": [0], // invalidateUnorderedNonces(wordPos:uint256,mask:uint256)
      // "0xbe75cba8": [0, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // lockdown(approvals:tuple[])
      // "0x355e5fcd": [1, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // permit(owner:address,permitBatch:tuple,signature:bytes)
      // "0xbe1696da": [1, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // permit(owner:address,permitSingle:tuple,signature:bytes)
      // "0x81e5e841": [3, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // permitTransferFrom(permit:tuple,transferDetails:tuple,owner:address,signature:bytes)
      // "0x44aa802b": [3, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // permitTransferFrom(permit:tuple,transferDetails:tuple[],owner:address,signature:bytes)
      // "0xf6e87c56": [3, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // permitWitnessTransferFrom(permit:tuple,transferDetails:tuple,owner:address,witness:bytes32,witnessTypeString:string,signature:bytes)
      // "0xc5867a85": [3, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // permitWitnessTransferFrom(permit:tuple,transferDetails:tuple[],owner:address,witness:bytes32,witnessTypeString:string,signature:bytes)
      // "0x9a812e85": [0, POLYGON_UNISWAP_SWAP_CHECKER_PARAM], // transferFrom(transferDetails:tuple[])
      "0x36c78516": [1, 2, 4], // transferFrom(from:address,to:address,amount:uint160,token:address)
    }),
  ],
};
