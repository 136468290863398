import { IWhitelistContractMap, IWhitelistFunctionMap } from "./types";
import { WhitelistContract, getCheckerParam } from "./utils";

const ETH_AAVE_LEND_CHECKER_ADDRESS: string =
  "0xe5b955f741c60fD9EA797F69353f9f372764cA22";
const ETH_UNISWAP_SWAP_CHECKER_ADDRESS: string =
  "0xF52BeAD419fdB3786A341Ad1d1f0dB9865B6125E";

const ETH_AAVE_LEND_CHECKER_PARAM = getCheckerParam(
  ETH_AAVE_LEND_CHECKER_ADDRESS
);
const ETH_UNISWAP_SWAP_CHECKER_PARAM = getCheckerParam(
  ETH_UNISWAP_SWAP_CHECKER_ADDRESS
);

export const ETH_TOKEN_WHITELIST_MAP: IWhitelistContractMap = {
  USDT: WhitelistContract("0xdAC17F958D2ee523a2206206994597C13D831ec7", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  USDC: WhitelistContract(
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // 0xa2327a938Febf5FEC13baCFb16Ae10EcBc4cbDCF
    {
      "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
      "0x5a049a70": [1], // cancelAuthorization(authorizer:address,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
      "0xa457c2d7": [1], // decreaseAllowance(spender:address,decrement:uint256)
      "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
      "0xd505accf": [1, 2], // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
      "0xef55bec6": [1, 2], // receiveWithAuthorization(from:address,to:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
      "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
      "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
      "0xe3ee160e": [1, 2], // transferWithAuthorization(from:address,to:address,value:uint256,validAfter:uint256,validBefore:uint256,nonce:bytes32,v:uint8,r:bytes32,s:bytes32)
    }
  ),
  DAI: WhitelistContract("0x6b175474e89094c44da98b954eedeac495271d0f", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0x8fcbaf0c": [1, 2], // permit(holder:address,spender:address,nonce:uint256,expiry:uint256,allowed:bool,v:uint8,r:bytes32,s:bytes32)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  WBTC: WhitelistContract("0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0x66188463": [1], // decreaseApproval(_spender:address,_subtractedValue:uint256)
    "0xd73dd623": [1], // increaseApproval(_spender:address,_addedValue:uint256)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  WETH: WhitelistContract("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", {
    "0x095ea7b3": [1], // approve(guy:address,wad:uint256)
    "0x23b872dd": [1, 2], // transferFrom(src:address,dst:address,wad:uint256)
    "0x2e1a7d4d": [0], // withdraw(wad:uint256)
    "0xa9059cbb": [1], // transfer(dst:address,wad:uint256)
    "0xd0e30db0": [0], // deposit()
  }),
  LINK: WhitelistContract("0x514910771AF9Ca656af840dff83E8264EcF986CA", {
    "0x095ea7b3": [1], // approve(_spender:address,_value:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_from:address,_to:address,_value:uint256)
    "0x4000aea0": [], // transferAndCall(_to:address,_value:uint256,_data:bytes)
    "0x66188463": [1], // decreaseApproval(_spender:address,_subtractedValue:uint256)
    "0xa9059cbb": [1], // transfer(_to:address,_value:uint256)
    "0xd73dd623": [1], // increaseApproval(_spender:address,_addedValue:uint256)
  }),
  UNI: WhitelistContract("0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984", {
    "0x095ea7b3": [1], // approve(spender:address,rawAmount:uint256)
    "0x5c19a95c": [1], // delegate(delegatee:address)
    "0xc3cda520": [1], // delegateBySig(delegatee:address,nonce:uint256,expiry:uint256,v:uint8,r:bytes32,s:bytes32)
    "0xd505accf": [1, 2], // permit(owner:address,spender:address,rawAmount:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
    "0xa9059cbb": [1], // transfer(dst:address,rawAmount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(src:address,dst:address,rawAmount:uint256)
  }),
  TUSD: WhitelistContract(
    "0x0000000000085d4780B73119b644AE5ecd22b376", // 0xB650eb28d35691dd1BD481325D40E65273844F9b
    {
      "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
      "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
      "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
      "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
      "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
    }
  ),
  LDO: WhitelistContract("0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_from:address,_to:address,_amount:uint256)
    "0xa9059cbb": [1], // transfer(_to:address,_amount:uint256)
    "0xcae9ca51": [], // approveAndCall(_spender:address,_amount:uint256,_extraData:bytes)
  }),
  ARB: WhitelistContract(
    "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1", // 0xAD0C361Ef902A7D9851Ca7DcC85535DA2d3C6Fc7
    {
      "0x095ea7b3": [1], // approve(spender:address,amount:uint256)
      "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
      "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
      "0xd505accf": [1, 2], // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
      "0xa9059cbb": [1], // transfer(to:address,amount:uint256)
      "0x4000aea0": [], // transferAndCall(_to:address,_value:uint256,_data:bytes)
      "0x23b872dd": [1, 2], // transferFrom(sender:address,recipient:address,amount:uint256)
    }
  ),
  GRT: WhitelistContract("0xc944E90C64B2c07662A292be6244BDf05Cda44a7", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    "0xd505accf": [1, 2],
    // permit(owner:address,spender:address,value:uint256,deadline:uint256,v:uint8,r:bytes32,s:bytes32)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  FRAX: WhitelistContract("0x853d955aCEf822Db058eb8505911ED77F175b99e", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  MKR: WhitelistContract("0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2", {
    "0x095ea7b3": [1], // approve(guy:address,wad:uint256)
    "0xdaea85c5": [1], // approve(guy:address)
    "0xa9059cbb": [1], // transfer(dst:address,wad:uint256)
    "0x23b872dd": [1, 2], // transferFrom(src:address,dst:address,wad:uint256)
  }),
  USDD: WhitelistContract("0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0xa457c2d7": [1], // decreaseAllowance(spender:address,subtractedValue:uint256)
    "0x39509351": [1], // increaseAllowance(spender:address,addedValue:uint256)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  CRV: WhitelistContract("0xD533a949740bb3306d119CC777fa900bA034cd52", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
  COMP: WhitelistContract("0xc00e94Cb662C3520282E6f5717214004A7f26888", {
    "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
    "0x5c19a95c": [1], // delegate(delegatee:address)
    "0xc3cda520": [1], // delegateBySig(delegatee:address,nonce:uint256,expiry:uint256,v:uint8,r:bytes32,s:bytes32)
    "0xa9059cbb": [1], // transfer(_recipient:address,_amount:uint256)
    "0x23b872dd": [1, 2], // transferFrom(_sender:address,_recipient:address,_amount:uint256)
  }),
};

export const ETH_APP_FUNCTION_WHITELIST_MAP: IWhitelistFunctionMap = {
  AAVE_LEND: [
    WhitelistContract(
      "0x87870Bca3F3fD6335C3F4ce8392D69350B4fA4E2", // Pool V3 [x]
      {
        "0xd65dc7a1": [1], // backUnbacked(asset:address,amount:uint256,fee:uint256)
        "0xa415bcad": [1, 5],
        // borrow(asset:address,amount:uint256,interestRateMode:uint256,referralCode:uint16,onBehalfOf:address)
        "0xd5eed868": [ETH_AAVE_LEND_CHECKER_PARAM], // borrow(args:bytes32)
        "0x74eb89ac": [], // configureEModeCategory(id:uint8,category:tuple) onlyPoolConfigurator
        "0xe8eda9df": [1, 3], // deposit(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16)
        "0x63c9b860": [1], // dropReserve(asset:address)
        "0xd5ed3933": [1, 2, 3],
        // finalizeTransfer(asset:address,from:address,to:address,amount:uint256,balanceFromBefore:uint256,balanceToBefore:uint256)
        "0xab9c4b5d": [],
        // [99, 1, 5], // flashLoan(receiverAddress:address,assets:address[],amounts:uint256[],interestRateModes:uint256[],onBehalfOf:address,params:bytes,referralCode:uint16)
        "0x42b0b77c": [],
        // [99, 1, 2], // flashLoanSimple(receiverAddress:address,asset:address,amount:uint256,params:bytes,referralCode:uint16)
        "0x7a708e92": [1, 2, 3, 4, 5],
        // initReserve(asset:address,aTokenAddress:address,stableDebtAddress:address,variableDebtAddress:address,interestRateStrategyAddress:address)
        "0xc4d66de8": [1], // initialize(provider:address)
        "0x00a718a9": [1, 2, 3],
        // liquidationCall(collateralAsset:address,debtAsset:address,user:address,debtToCover:uint256,receiveAToken:bool)
        "0xfd21ecff": [ETH_AAVE_LEND_CHECKER_PARAM], // liquidationCall(args1:bytes32,args2:bytes32)
        "0x9cd19996": [ETH_AAVE_LEND_CHECKER_PARAM], // mintToTreasury(assets:address[])
        "0x69a933a5": [1, 3],
        // mintUnbacked(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16)
        "0x427da177": [ETH_AAVE_LEND_CHECKER_PARAM], // rebalanceStableBorrowRate(args:bytes32)
        "0xcd112382": [1, 2], // rebalanceStableBorrowRate(asset:address,user:address)
        "0x563dd613": [ETH_AAVE_LEND_CHECKER_PARAM], // repay(args:bytes32)
        "0x573ade81": [1, 4], // repay(asset:address,amount:uint256,interestRateMode:uint256,onBehalfOf:address)
        "0x2dad97d4": [1], // repayWithATokens(asset:address,amount:uint256,interestRateMode:uint256)
        "0xdc7c0bff": [ETH_AAVE_LEND_CHECKER_PARAM], // repayWithATokens(args:bytes32)
        "0x94b576de": [], // Permit not supported [99],  // repayWithPermit(args:bytes32,r:bytes32,s:bytes32)
        "0xee3e210b": [],
        // Permit not supported [1, 4], // repayWithPermit(asset:address,amount:uint256,interestRateMode:uint256,onBehalfOf:address,deadline:uint256,permitV:uint8,permitR:bytes32,permitS:bytes32)
        "0xcea9d26f": [1, 2], // rescueTokens(token:address,to:address,amount:uint256)
        "0xe43e88a1": [1], // resetIsolationModeTotalDebt(asset:address)
        "0x5b1048bb": [], // setConfiguration(asset:address,configuration:tuple) onlyPoolConfigurator
        "0x1d2118f9": [1, 2],
        // setReserveInterestRateStrategyAddress(asset:address,rateStrategyAddress:address)
        "0x28530a47": [0], // setUserEMode(categoryId:uint8)
        "0x4d013f03": [ETH_AAVE_LEND_CHECKER_PARAM], // setUserUseReserveAsCollateral(args:bytes32)
        "0x5a3b74b9": [1], // setUserUseReserveAsCollateral(asset:address,useAsCollateral:bool)
        "0x617ba037": [1, 3], // supply(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16)
        "0xf7a73840": [ETH_AAVE_LEND_CHECKER_PARAM], // supply(args:bytes32)
        "0x02c205f0": [],
        // Permit not supported [99, 1, 3], // supplyWithPermit(asset:address,amount:uint256,onBehalfOf:address,referralCode:uint16,deadline:uint256,permitV:uint8,permitR:bytes32,permitS:bytes32)
        "0x680dd47c": [], // Permit not supported [99],  // supplyWithPermit(args:bytes32,r:bytes32,s:bytes32)
        "0x1fe3c6f3": [ETH_AAVE_LEND_CHECKER_PARAM], // swapBorrowRateMode(args:bytes32)
        "0x94ba89a2": [1], // swapBorrowRateMode(asset:address,interestRateMode:uint256)
        "0x3036b439": [0], // updateBridgeProtocolFee(protocolFee:uint256)
        "0xbcb6e522": [0],
        // updateFlashloanPremiums(flashLoanPremiumTotal:uint128,flashLoanPremiumToProtocol:uint128)
        "0x69328dec": [1, 3], // withdraw(asset:address,amount:uint256,to:address)
        "0x8e19899e": [ETH_AAVE_LEND_CHECKER_PARAM], // withdraw(args:bytes32)
      }
    ),
    WhitelistContract(
      "0xD322A49006FC828F9B5B37Ab215F99B4E5caB19C", // Wrapped Token Gateway V3
      {
        "0x66514c97": [0], // borrowETH(:address,amount:uint256,interesRateMode:uint256,referralCode:uint16)
        "0x474cf53d": [2], // depositETH(:address,onBehalfOf:address,referralCode:uint16)
        "0xeed88b8d": [1], // emergencyEtherTransfer(to:address,amount:uint256)
        "0xa3d5b255": [1, 2], // emergencyTokenTransfer(token:address,to:address,amount:uint256)
        "0x715018a6": [0], // renounceOwnership()
        "0x02c5fcf8": [4], // repayETH(:address,amount:uint256,rateMode:uint256,onBehalfOf:address)
        "0xf2fde38b": [1], // transferOwnership(newOwner:address)
        "0x80500d20": [3], // withdrawETH(:address,amount:uint256,to:address)
        "0xd4c40b6c": [3],
        // withdrawETHWithPermit(:address,amount:uint256,to:address,deadline:uint256,permitV:uint8,permitR:bytes32,permitS:bytes32)
      }
    ),
    WhitelistContract(
      "0x8f30ADaA6950b31f675bF8a709Bc23F55aa24735", // For ParaSwapDebtSwapAdapterV3 [x]
      {
        "0x920f5c84": [],
        // [99],  // executeOperation(assets:address[],amounts:uint256[],:uint256[],initiator:address,params:bytes)
        "0x0a036351": [1], // renewAllowance(reserve:address)
        "0x715018a6": [0], // renounceOwnership()
        "0x00ae3bf8": [1], // rescueTokens(token:address)
        "0x636aa619": [], // [99],  // swapDebt(debtSwapParams:tuple,creditDelegationPermit:tuple)
        "0xf2fde38b": [1], // transferOwnership(newOwner:address)
      }
    ),
    WhitelistContract(
      "0x135896DE8421be2ec868E0b811006171D9df802A", // For ParaSwapLiquiditySwapAdapter [x]
      {
        "0x1b11d0ff": [],
        // [99,1,4], // executeOperation(asset:address,amount:uint256,premium:uint256,initiator:address,params:bytes)
        "0x715018a6": [0], // renounceOwnership()
        "0x00ae3bf8": [1], // rescueTokens(token:address)
        "0xd3454a35": [],
        // [99,1,2,7], // swapAndDeposit(assetToSwapFrom:address,assetToSwapTo:address,amountToSwap:uint256,minAmountToReceive:uint256,swapAllBalanceOffset:uint256,swapCalldata:bytes,augustus:address,permitParams:tuple)
        "0xf2fde38b": [1], // transferOwnership(newOwner:address)
      }
    ),
    WhitelistContract(
      "0xeA51d7853EEFb32b6ee06b1C12E6dcCA88Be0fFE", // vWETH Token v3
      {
        "0xc04a8a10": [1], // approveDelegation(delegatee:address,amount:uint256)
      }
    ),
    WhitelistContract(
      "0x102633152313C81cD80419b6EcF66d14Ad68949A", // sWETH Token v3
      {
        "0xc04a8a10": [1], // approveDelegation(delegatee:address,amount:uint256)
      }
    ),
    WhitelistContract(
      "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8", // aWETH Token v3
      {
        "0x095ea7b3": [1], // approve(_spender:address,_amount:uint256)
      }
    ),
  ],
  UNISWAP_SWAP: [
    WhitelistContract(
      "0x000000000022D473030F116dDEE9F6B43aC78BA3", // Permit2 [x]
      {
        "0x87517c45": [1, 2], // approve(token:address,spender:address,amount:uint160,expiration:uint48)
        "0x65d9723c": [1, 2], // invalidateNonces(token:address,spender:address,newNonce:uint48)
        "0x3ff9dcb1": [0], // invalidateUnorderedNonces(wordPos:uint256,mask:uint256)
        "0xab1bc1f8": [], // [99],  // lockdown(approvals:tuple[])
        "0xe4d1b8e4": [],
        // [99],  // permit(owner:address,permitBatch:tuple,signature:bytes) / permit(owner:address,permitSingle:tuple,signature:bytes)
        "0x8c1b8baa": [],
        // [99],  // permitTransferFrom(permit:tuple,transferDetails:tuple,owner:address,signature:bytes)
        "0xd8ea8a80": [],
        // [99],  // permitTransferFrom(permit:tuple,transferDetails:tuple[],owner:address,signature:bytes)
        "0x9f97ecd0": [],
        // [99],  // permitWitnessTransferFrom(permit:tuple,transferDetails:tuple,owner:address,witness:bytes32,witnessTypeString:string,signature:bytes)
        "0xcec4be07": [],
        // [99],  // permitWitnessTransferFrom(permit:tuple,transferDetails:tuple[],owner:address,witness:bytes32,witnessTypeString:string,signature:bytes)
        "0x855a2299": [], // [99],  // transferFrom(transferDetails:tuple[])
        "0x36c78516": [1, 2, 4], // transferFrom(from:address,to:address,amount:uint160,token:address)
      }
    ),
    WhitelistContract(
      "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD", // UniversalRouter [x]
      {
        "0x709a1cc2": [], // [99],  // collectRewards(looksRareClaim:bytes)
        "0x24856bc3": [ETH_UNISWAP_SWAP_CHECKER_PARAM], // execute(commands:bytes,inputs:bytes[])
        "0x3593564c": [ETH_UNISWAP_SWAP_CHECKER_PARAM],
        // execute(commands:bytes,inputs:bytes[],deadline:uint256)
        "0xbc197c81": [0], // onERC1155BatchReceived(:address,:address,:uint256[],:uint256[],:bytes)
        "0xf23a6e61": [0], // onERC1155Received(:address,:address,:uint256,:uint256,:bytes)
        "0x150b7a02": [0], // onERC721Received(:address,:address,:uint256,:bytes)
        "0x01ffc9a7": [0], // supportsInterface(interfaceId:bytes4)
        "0xfa461e33": [], // [99],  // uniswapV3SwapCallback(amount0Delta:int256,amount1Delta:int256,data:bytes)
      }
    ),
  ],
};
