import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isNil } from "lodash";
import useAddressMapping from "../hooks/useAddressMapping";
import renameAddressModalState from "../atom/renameAddressModalState";
import { minAddr } from "../utils/display";
import ssoUserState from "../atom/ssoUserState";

interface IModifyAddress {
  address: string;
  canModify?: boolean;
  showShort?: boolean;
  alwaysShort?: boolean;
  prefix?: string | ReactNode;
}

const ModifyAddress = ({
  address,
  canModify = false,
  showShort = false,
  alwaysShort = false,
  prefix = null,
}: IModifyAddress) => {
  const { mapAddr, onFindAddress } = useAddressMapping();
  const [ssoUser] = useRecoilState(ssoUserState);
  const findAddress = onFindAddress(address);
  const setOpenRenameModal = useSetRecoilState(renameAddressModalState);

  const allowModify = canModify && !isNil(ssoUser);
  const needShowShort = showShort && findAddress;

  return (
    <Typography
      component="span"
      title={address}
      sx={{ cursor: allowModify ? "pointer" : "auto" }}
      onClick={() =>
        allowModify &&
        setOpenRenameModal(
          !findAddress
            ? { address }
            : {
                address,
                id: findAddress.id,
                nickName: findAddress.nickName,
              }
        )
      }
    >
      {!findAddress ? prefix : null}
      {(() => {
        if (findAddress) {
          return mapAddr(address);
        }
        if (alwaysShort) {
          return minAddr(address);
        }
        return address;
      })()}
      {needShowShort && ` (`}
      {needShowShort && prefix}
      {needShowShort && `${minAddr(address)})`}
    </Typography>
  );
};

export default ModifyAddress;

// const StyleLayer = styled(Box)(() => ({
//   display: "inline-flex",
//   alignItems: "center",
// }));
