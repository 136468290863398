export const ARB_MASTER_ADDR: string =
  "0xb12E0B98F031Ed3BE79fdAAD965bd431AF7C1241";
export const ARB_FACTORY_ADDR: string =
  "0xB268792482ab1Cfe6540015302B378bBCa773B4a";
export const ARB_MASTER_ADDR_WHITE_LIST: string[] = [
  ARB_MASTER_ADDR,
  "0xB5747796020A7FEA3f444CC04920AbfF52CE6414",
  "0x569f575965075e5311DeCfC1f3160259Fb91989F",
];
export const ETH_MASTER_ADDR: string =
  "0x6a8913D4D1984fb527a8897Df62eFe876563b64B";
export const ETH_FACTORY_ADDR: string =
  "0x71e47D2602d96a834885a1304bF4179F62C28CBC";
export const ETH_MASTER_ADDR_WHITE_LIST: string[] = [ETH_MASTER_ADDR];

export const AVAX_MASTER_ADDR: string =
  "0xb12E0B98F031Ed3BE79fdAAD965bd431AF7C1241";
export const AVAX_FACTORY_ADDR: string =
  "0xe5b955f741c60fD9EA797F69353f9f372764cA22";
export const AVAX_MASTER_ADDR_WHITE_LIST: string[] = [AVAX_MASTER_ADDR];

export const POLYGON_MASTER_ADDR: string =
  "0xA2EdeD51E747756BB21661aDcc8A2Fd5a2fC0c3A";
export const POLYGON_FACTORY_ADDR: string =
  "0x34b2Fd50C5bD2Ed52aEe50309A9dbDF379DfB520";
export const POLYGON_MASTER_ADDR_WHITE_LIST: string[] = [POLYGON_MASTER_ADDR];

export const KS_WALLET_SUFFIX: string = "KnightSafe";
export const EOA_SUFFIX: string = "EOA";
export const CONTRACT_SUFFIX: string = "ConContract";
