import { Web3 } from "web3";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";

export const checkIsKnightSafe = async (addr: string, chain: string) => {
  if (!addr || !chain) {
    return false;
  }

  const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
  const firstUnit256 = await web3.eth.getStorageAt(addr, 0);
  if (!firstUnit256) {
    return false;
  }
  const isValid = CHAIN_ID_MAPPING[chain].masterAddrWhiteList.includes(
    web3.utils.toChecksumAddress(firstUnit256.slice(26, 66))
  );

  if (!isValid) {
    return false;
  }
  return true;
};

export const checkIsEOA = async (
  addr: string,
  chain: string
): Promise<boolean> => {
  try {
    const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
    const addrCode = await web3.eth.getCode(addr);
    return addrCode.length <= 2;
  } catch (error) {
    return false;
  }
};
