import { atom } from "recoil";

export interface ITokensForOwner {
  contractAddress?: string;
  rawBalance?: string;
  decimals?: number;
  logo?: string;
  name?: string;
  symbol?: string;
  balance?: string;
}

export interface IProfile {
  address: `0x${string}`;
  owner: `0x${string}`;
  pendingOwner: `0x${string}`;
  isGasRefund: boolean;
  traders: `0x${string}`[];
  app: string[];
  updateApp: string[];
  token: string[];
  wallet: string[];
  other: string[];
  nativeBalance: string;
  tokensForOwner: ITokensForOwner[];
  lastUpdateTime: Date;
}

const profileState = atom<IProfile | null>({
  key: "profileState",
  default: null,
});

export default profileState;
