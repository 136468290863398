import Web3 from "web3";
import axios from "axios";
import { Contract } from "web3-eth-contract";
import { soliditySha3, toChecksumAddress } from "web3-utils";
import { isArray } from "lodash";
import { ARB_RPC, AVAX_RPC, ETH_RPC, POLYGON_RPC } from "../constant/rpc";
import CHAIN_ID_MAPPING, {
  ARB_ID,
  AVAX_ID,
  ETH_ID,
  POLYGON_ID,
} from "../constant/CHAIN_ID_MAPPING";
import erc20_abi from "../constant/contract/erc20_abi";

export interface IScanInfo {
  rpc: string;
  url: string;
  key: string;
}

export interface IAbiBaseFunc {
  inputs: IAbiBaseFuncInput[];
  stateMutability?: string;
  type: string;
  anonymous?: boolean;
  name?: string;
  outputs?: IAbiBaseFuncOutput[];
  selector?: string;
}

export interface IAbiBaseFuncInput {
  internalType: string;
  name: string;
  type: string;
  indexed?: boolean;
  components?: IAbiBaseFuncComponent[];
}

export interface IAbiBaseFuncComponent {
  internalType: string;
  name: string;
  type: string;
  components?: IAbiBaseFuncComponent[];
}

export interface IAbiBaseFuncOutput {
  internalType: string;
  name: string;
  type: string;
  components?: IAbiBaseFuncComponent[];
}

export interface IContractDetail {
  readEvt: IAbiBaseFunc[];
  writeEvt: IAbiBaseFunc[];
  contractData: IAbiBaseFunc[];
  contract?: Contract<any>;
}

export interface IErc20BasicInfo {
  name: string | undefined;
  decimals: string | undefined;
  symbol: string | undefined;
}

const readRequiredFunc = [
  "name",
  "decimals",
  "symbol",
  "totalSupply",
  "balanceOf",
];
const writeRequiredFunc = ["approve", "transfer"];
const writeFunc = [
  ...writeRequiredFunc,
  "decreaseAllowance",
  "increaseAllowance",
  "transferFrom",
  "permit",
];

export const getScan = (chain: string): IScanInfo | undefined => {
  let rpc;
  let url;
  let key;

  switch (chain) {
    case ARB_ID:
      rpc = ARB_RPC;
      url = "https://api.arbiscan.io";
      key = process.env.REACT_APP_ARB_SCAN_KEY!;
      break;
    case ETH_ID:
      rpc = ETH_RPC;
      url = "https://api.etherscan.io";
      key = process.env.REACT_APP_ETH_SCAN_KEY!;
      break;
    case AVAX_ID:
      rpc = AVAX_RPC;
      url = "https://api.snowtrace.io";
      key = process.env.REACT_APP_AVAX_SCAN_KEY!;
      break;
    case POLYGON_ID:
      rpc = POLYGON_RPC;
      url = "https://api.polygonscan.com";
      key = process.env.REACT_APP_POLYGON_SCAN_KEY!;
      break;
    default:
      return;
  }

  return { rpc, url, key };
};

export const getSignature = (func: IAbiBaseFunc): string => {
  if (!func) {
    return "";
  }

  const sha = soliditySha3({
    type: "string",
    value: `${func.name}(${func.inputs
      .map((d) =>
        d.type === "tuple" && isArray(d.components)
          ? `(${d.components.map((c) => c.type).join(",")})`
          : d.type
      )
      .join(",")})`,
  });

  if (!sha) {
    return "";
  }
  return sha.slice(0, 10);
};

export interface IContractDataRes {
  contract: Contract<any>;
  contractData: IAbiBaseFunc[];
  address: string;
}

export const getRealContractAddr = async (
  addr: string,
  chain: string,
  web3: Web3
): Promise<IContractDataRes> => {
  let targetAddr = addr;
  let temp = targetAddr;
  let contract;
  let contractData;
  while (true) {
    try {
      const getConRes = await getContract(targetAddr, chain);
      contract = getConRes.contract;
      contractData = getConRes.contractData;
      targetAddr = await contract.methods.implementation().call();
    } catch (e) {
      try {
        const gsa = await web3.eth.getStorageAt(
          targetAddr,
          "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc"
        );
        if (
          gsa !==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          targetAddr = "0x" + gsa.slice(26, 66);
        } else {
          const gsb = await web3.eth.getStorageAt(
            targetAddr,
            "0xa3f0ad74e5423aebfd80d3ef4346578335a9a72aeaee59ff6cb3582b35133d50"
          );
          if (
            gsb !==
            "0x0000000000000000000000000000000000000000000000000000000000000000"
          ) {
            targetAddr = "0x" + gsb.slice(26, 66);
          }
        }
      } catch (e) {}
    }
    // if nothing changed targetAddr, then end loop
    if (temp === targetAddr) {
      break;
    } else {
      temp = targetAddr;
    }
  }
  if (!contract || !contractData) {
    throw new Error("Cannot get real address");
  }
  return { address: targetAddr, contract, contractData };
};

export const getAddTokenFuncParam = (funcs: IAbiBaseFunc[]) => {
  const targetFunc = funcs.filter((p) => writeFunc.includes(p?.name!));
  const res: { [c: string]: number[] } = {};
  targetFunc.forEach((p) => {
    if (p?.selector && isArray(p?.inputs)) {
      res[p.selector] = p.inputs
        .filter((c) => c.type === "address")
        .map((v, i) => i + 1);
    }
  });
  return res;
};

const getContract = async (
  addr: string,
  chain: string
): Promise<IContractDataRes> => {
  const scanD = getScan(chain);
  if (!scanD) {
    throw new Error("scan info not found");
  }
  const { rpc, url: api_url_base, key: api_key } = scanD;
  const web3 = new Web3(rpc);
  const res = await axios.get(
    `${api_url_base}/api?module=contract&action=getabi&address=${addr}&apikey=${api_key}`
  );

  const contractData = JSON.parse(res.data.result);
  const contract = new web3.eth.Contract(
    JSON.parse(res.data.result),
    toChecksumAddress(addr)
  );

  return { contract, contractData, address: addr };
};

export const getErc20BasicInfo = async (
  addr: string,
  chain: string
): Promise<IErc20BasicInfo> => {
  try {
    const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
    const contract = new web3.eth.Contract(erc20_abi, toChecksumAddress(addr));

    const res: any[] = await Promise.all(
      ["name", "decimals", "symbol"].map((p) => contract.methods[p]().call())
    );
    return {
      name: res[0],
      decimals: res[1],
      symbol: res[2],
    };
  } catch (err) {
    console.error(err);
    return {
      name: undefined,
      decimals: undefined,
      symbol: undefined,
    };
  }
};

export const getContractDetail = async (
  addr: string,
  chain: string
): Promise<IContractDetail | undefined> => {
  try {
    const scanD = getScan(chain);
    if (!scanD) {
      return undefined;
    }
    const web3 = new Web3(scanD.rpc);
    let { contractData, contract } = await getRealContractAddr(
      addr,
      chain,
      web3
    );
    return {
      contract,
      contractData,
      readEvt: contractData
        .filter((p) => p.type === "function" && p.stateMutability === "view")
        .map((p) => ({ ...p, selector: getSignature(p) })),
      writeEvt: contractData
        .filter(
          (p) => p.type === "function" && p.stateMutability === "nonpayable"
        )
        .map((p) => ({ ...p, selector: getSignature(p) })),
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const checkIsErc20Address = (addrData: IContractDetail): boolean => {
  if (!isArray(addrData?.contractData)) {
    return false;
  }
  if (
    addrData.contractData.filter((p) => readRequiredFunc.includes(p?.name!))
      .length !== readRequiredFunc.length
  ) {
    return false;
  }
  if (
    addrData.contractData.filter((p) => writeRequiredFunc.includes(p?.name!))
      .length !== writeRequiredFunc.length
  ) {
    return false;
  }
  return true;
};
