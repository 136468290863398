import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";
import { useSnackbar } from "notistack";
import myLinkModalState from "../atom/myLinkModalState";
import QrCodeBox from "./QrCodeBox";

interface IModalMyLink {
  canCopy?: boolean;
}

const ModalMyLink = ({ canCopy = true }: IModalMyLink) => {
  const [open, setOpen] = useRecoilState(myLinkModalState);
  const { t } = useTranslation();
  const [qrValue, setQrValue] = useState(() => window?.location?.href || "");
  const { enqueueSnackbar } = useSnackbar();
  const [_, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    setQrValue(window?.location?.href);
  }, [open]);

  return (
    <StyleLayer open={open} onClose={() => setOpen(false)}>
      <Box className="mod-content">
        <h2>{t("SHARE_TIT")}</h2>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {canCopy ? (
            <TextField
              variant="outlined"
              fullWidth
              value={qrValue}
              onChange={(e) => setQrValue(e.target.value)}
            />
          ) : (
            <Typography
              component="p"
              sx={{ maxWidth: "100%", wordWrap: "break-word" }}
            >
              {qrValue}
            </Typography>
          )}
          {canCopy && (
            <Box sx={{ ml: "10px", flexShrink: 0 }}>
              <Button
                variant="contained"
                onClick={() => {
                  copyToClipboard(qrValue);
                  enqueueSnackbar(t("COPIED"));
                }}
              >
                Copy
              </Button>
            </Box>
          )}
        </Box>
        <QrCodeBox value={qrValue} />
        <IconButton className="close-btn" onClick={() => setOpen(false)}>
          <AiOutlineCloseCircle />
        </IconButton>
      </Box>
    </StyleLayer>
  );
};

export default ModalMyLink;

const StyleLayer = styled(Modal)({
  ".wal-list": {
    marginTop: "15px",
    button: {
      width: "100%",
      textAlign: "left",
      textTransform: "none",
      paddingTop: "8px",
      paddingBottom: "8px",
      img: { width: "30px", display: "inline-block", marginRight: "10px" },
    },
  },
});
