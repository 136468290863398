import { atom } from "recoil";

export interface IAddressMapping {
  id?: string;
  ksProfile: string;
  nickName: string;
  userId: string;
  updateTime?: any;
  chain: string;
  address: string;
  createTime?: number;
  isKS?: boolean;
}

const addressMappingState = atom<IAddressMapping[]>({
  key: "addressMappingState",
  default: [],
});

export default addressMappingState;
