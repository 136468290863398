import { atom } from "recoil";

export interface ITokenRate {
  [n: string]: number;
}

const tokenRateState = atom<ITokenRate>({
  key: "tokenRateState",
  default: {},
});

export default tokenRateState;
