import { atom } from "recoil";

const chainState = atom<string>({
  key: "chainState",
  default: "",
});

export const DEFAULT_CHAIN = "0xa4b1";

export default chainState;
