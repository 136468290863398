import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { red } from "@mui/material/colors";
import { AiOutlineCloseCircle, AiOutlineArrowDown } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import renameAddressModalState from "../atom/renameAddressModalState";
import useAddressMapping from "../hooks/useAddressMapping";
import { enqueueSnackbar } from "notistack";
import { minAddr } from "../utils/display";
import isLoadingModState from "../atom/isLoadingModState";
import { validShortName } from "../utils/validation";
import confirmModalState from "../atom/confirmModalState";

const ModalRenameAddress = () => {
  const [open, setOpen] = useRecoilState(renameAddressModalState);
  const { onUpdateAddress, onFetchAddress, onDeleteAddress } =
    useAddressMapping();
  const [nickName, setNickName] = useState("");
  const [inputError, setInputError] = useState("");
  const setLoadingOpen = useSetRecoilState(isLoadingModState);
  const setOpenConfirm = useSetRecoilState(confirmModalState);
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      setNickName("");
      setInputError("");
    } else {
      setNickName(open?.nickName || "");
    }
  }, [open]);

  const onUpdate = useCallback(async () => {
    if (!open || !nickName || open?.nickName === nickName) {
      return;
    }
    setInputError("");
    const checkNickname = validShortName(nickName);
    if (checkNickname !== true) {
      setInputError(checkNickname);
      return;
    }
    try {
      setLoadingOpen(true);
      await onUpdateAddress(open.address, nickName, open?.id);
      const frontWord = open?.nickName || minAddr(open.address);
      enqueueSnackbar(`Updated ${frontWord} to ${nickName}`, {
        variant: "success",
      });
      setOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Update failed, please try again", {
        variant: "default",
      });
    } finally {
      setLoadingOpen(false);
    }
    onFetchAddress();
  }, [
    nickName,
    onFetchAddress,
    onUpdateAddress,
    open,
    setLoadingOpen,
    setOpen,
  ]);

  const onDelete = useCallback(() => {
    if (!open || !open?.id) {
      return;
    }
    setOpenConfirm({
      size: "sm",
      message: "Are you sure to remove this nickname?",
      yes: async () => {
        try {
          setLoadingOpen(true);
          await onDeleteAddress(open.id!);
          enqueueSnackbar(`Deleted ${open.nickName}`, {
            variant: "success",
          });
          setOpen(false);
        } catch (err) {
          console.error(err);
          enqueueSnackbar("Delete failed, please try again", {
            variant: "default",
          });
        } finally {
          setLoadingOpen(false);
        }
        onFetchAddress();
      },
    });
  }, [
    onDeleteAddress,
    onFetchAddress,
    open,
    setLoadingOpen,
    setOpen,
    setOpenConfirm,
  ]);

  if (!open) {
    return null;
  }

  return (
    <StyleLayer open={!!open}>
      <Box className="mod-content">
        <Box sx={{ display: "flex" }}>
          <h2>Rename Address</h2>
        </Box>
        <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.5)", mb: "20px" }} />
        <Typography component="p">Original Address</Typography>
        <Input className="search-input" value={open.address} readOnly />
        <Box sx={{ display: "flex", alignItems: "center", mt: "16px" }}>
          <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.5)", flex: 1 }} />
          <AiOutlineArrowDown size={36} color="#8061FF" />
          <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.5)", flex: 1 }} />
        </Box>
        <Typography component="p">Nickname</Typography>
        <Input
          className="search-input"
          value={nickName}
          onChange={(e) => setNickName(e.target.value)}
        />
        {inputError && (
          <Typography variant="caption" sx={{ color: red[400] }}>
            {inputError}
          </Typography>
        )}
        <Box sx={{ display: "flex", mt: "20px", justifyContent: "flex-end" }}>
          {open?.id && (
            <Button
              variant="text"
              sx={{ mr: "20px" }}
              onClick={() => onDelete()}
            >
              Remove nickname
            </Button>
          )}
          <Button variant="contained" onClick={() => onUpdate()}>
            {"Update"}
          </Button>
          <IconButton className="close-btn" onClick={() => setOpen(false)}>
            <AiOutlineCloseCircle />
          </IconButton>
        </Box>
      </Box>
    </StyleLayer>
  );
};

export default ModalRenameAddress;

const StyleLayer = styled(Modal)(({ theme }) => ({
  ".mod-content": {
    width: "550px",
  },
  p: {
    marginTop: "10px",
  },
  ".selected-asset": {
    display: "flex",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    padding: "12px 20px",
    borderRadius: "10px",
    marginTop: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    ".bal-box": {
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
      },
    },
    "> div": {
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        margin: "6px 0",
      },
    },
    p: {
      margin: "0",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "6px 10px",
    },
  },
  ".search-input": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    padding: "8px 20px",
    borderRadius: "10px",
    marginTop: "5px",
    width: "100%",
    "&::before": { content: "none", display: "none" },
  },
  ".search-select": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "10px",
    width: "100%",
    "> .MuiSelect-select": {
      paddingTop: "12px",
      paddingBottom: "13px",
      paddingLeft: "20px",
    },
    "&::before": { content: "none", display: "none" },
  },
}));
