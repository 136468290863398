import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { get } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import chainState from "../atom/chainState";
import profileState from "../atom/profileState";
import useLogout from "../hooks/useLogout";
import isLoadingModState from "../atom/isLoadingModState";

interface ISelectChain {
  changeToFetch?: boolean;
}

const SelectChain = ({ changeToFetch = false }: ISelectChain) => {
  const [chain, setChain] = useRecoilState(chainState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [, setLoadingOpen] = useRecoilState(isLoadingModState);
  const onLogout = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const nav = useNavigate();

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setChain(event.target.value);
    },
    [setChain]
  );

  const onSwitchWalletChain = useCallback(
    async (event: SelectChangeEvent) => {
      setLoadingOpen(true);
      if (!profile?.address) {
        return;
      }

      onLogout();
      enqueueSnackbar(t("PLES_ANOT_ADDRE"), {
        variant: "default",
      });
      handleChange(event);
      setLoadingOpen(false);
      // try to fetch again, but disable now
      // try {
      //   const pendingChain = event.target.value;
      //   const isKS = await checkIsKnightSafe(profile?.address, pendingChain);
      //   if (!isKS) {
      //     onLogout();
      //     enqueueSnackbar(t("PLES_ANOT_ADDRE"), {
      //       variant: "default",
      //     });
      //     return;
      //   }
      //   const res = await getKnightSafeProfile(profile?.address, pendingChain);
      //   if (!isEmpty(res)) {
      //     nav({
      //       search: createSearchParams({
      //         chain: pendingChain,
      //         address: profile.address,
      //       }).toString(),
      //     });
      //     setProfile(() => res);
      //     enqueueSnackbar(
      //       `${t("AUTO_DETECT")}${CHAIN_ID_MAPPING[pendingChain].title}`,
      //       {
      //         variant: "success",
      //       }
      //     );
      //   }
      // } catch (e) {
      // } finally {
      //   handleChange(event);
      //   setLoadingOpen(false);
      // }
    },
    [
      enqueueSnackbar,
      handleChange,
      onLogout,
      profile?.address,
      setLoadingOpen,
      t,
    ]
  );
  if (!chain) {
    return null;
  }

  return (
    <Select
      value={chain}
      onChange={changeToFetch ? onSwitchWalletChain : handleChange}
      size="small"
    >
      {Object.keys(CHAIN_ID_MAPPING).map((k) => (
        <MenuItem value={k} key={k}>
          {get(CHAIN_ID_MAPPING, `${k}.title`, "").toString()}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectChain;
