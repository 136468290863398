import { atom } from "recoil";

interface ISsoUserState {
  email: string;
  name?: string;
  picture?: string;
  locale?: string;
  given_name?: string;
  family_name?: string;
  exp?: number;
  "goo-credential"?: string;
}

const ssoUserState = atom<ISsoUserState | null>({
  key: "ssoUserState",
  default: null,
});

export default ssoUserState;
