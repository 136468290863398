import { useRecoilState } from "recoil";
import { useCallback } from "react";
import Web3 from "web3";
import { get, has, isNil, isNumber, isString } from "lodash";
import { utils } from "ethers";
import tokenRateState from "../atom/tokenRateState";
import aggregatorV3InterfaceABI from "../constant/contract/AggregatorV3Interface_abi";
import chainState from "../atom/chainState";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import TOKEN_LIST from "../constant/TOKEN_LIST";
import { compareAddr } from "../utils/display";
import NATIVE_CURRENCY_LIST from "../constant/NATIVE_CURRENCY_LIST";

export const getNativeCurrencyAddr = (chain: string) =>
  Object.values(NATIVE_CURRENCY_LIST).find((r) => !!r.address[chain])?.address[
    chain
  ];

const useGetTokenRate = () => {
  const [tokenRate, setTokenRateState] = useRecoilState(tokenRateState);
  const [chain] = useRecoilState(chainState);

  const getTokenRate = useCallback(
    async (tokenAddr: string | undefined) => {
      if (isNil(tokenAddr)) {
        return;
      }
      if (has(tokenRate, tokenAddr)) {
        console.log("no need get");
        return tokenRate[tokenAddr];
      }
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const chainLinkTarget = Object.values(TOKEN_LIST).find((p) =>
        !!get(p, `address.${chain}`)
          ? compareAddr(p.address[chain], tokenAddr)
          : false
      );
      if (!chainLinkTarget) {
        return;
      }
      const chainLinkAddr = chainLinkTarget.chainlink?.[chain];
      if (!chainLinkAddr) {
        return;
      }
      try {
        const priceFeed = new web3.eth.Contract(
          aggregatorV3InterfaceABI,
          chainLinkAddr
        );
        const decimals = await priceFeed.methods.decimals().call();
        const roundData = await priceFeed.methods.latestRoundData().call();
        const rate = +utils.formatUnits(roundData.answer, decimals);
        setTokenRateState((v) => ({ ...v, [tokenAddr]: rate }));
        return rate;
      } catch (e) {
        console.error(e);
      }
    },
    [chain, setTokenRateState, tokenRate]
  );

  const getNativeCurrencyRate = useCallback(async () => {
    const nativeCurrAddr = getNativeCurrencyAddr(chain) as string;
    if (!isString(nativeCurrAddr)) {
      return;
    }
    const rate = await getTokenRate(nativeCurrAddr);
    if (!isNumber(rate)) {
      return;
    }
    setTokenRateState((v) => ({ ...v, [nativeCurrAddr]: rate }));
    return rate;
  }, [chain, getTokenRate, setTokenRateState]);

  return { getTokenRate, getNativeCurrencyRate };
};

export default useGetTokenRate;
