import AVAX_img from "../assets/images/token/avax.svg";
import eth_logo from "../assets/images/token/ETH.png";
import MATIC_img from "../assets/images/token/matic.png";
import { ARB_ID, AVAX_ID, ETH_ID, POLYGON_ID } from "./CHAIN_ID_MAPPING";
import TOKEN_LIST from "./TOKEN_LIST";

interface INativeCurrency {
  symbol: string;
  img: string;
  title: string;
  chainlink: { [n: string]: string };
  address: { [n: string]: string };
}

export interface INativeCurrencyList {
  [n: string]: INativeCurrency;
}

const NATIVE_CURRENCY_LIST: INativeCurrencyList = {
  ETH: {
    symbol: "ETH",
    img: eth_logo,
    title: "Ether",
    chainlink: {
      [ARB_ID]: TOKEN_LIST.WETH.chainlink![ARB_ID],
      [ETH_ID]: TOKEN_LIST.WETH.chainlink![ETH_ID],
    },
    address: {
      [ARB_ID]: TOKEN_LIST.WETH.address[ARB_ID],
      [ETH_ID]: TOKEN_LIST.WETH.address[ETH_ID],
    },
  },
  AVAX: {
    symbol: "AVAX",
    img: AVAX_img,
    title: "Avalanche",
    chainlink: {
      [AVAX_ID]: TOKEN_LIST.WAVAX.chainlink![AVAX_ID],
    },
    address: {
      [AVAX_ID]: TOKEN_LIST.WAVAX.address[AVAX_ID],
    },
  },
  MATIC: {
    symbol: "MATIC",
    img: MATIC_img,
    title: "Matic",
    chainlink: {
      [POLYGON_ID]: TOKEN_LIST.WMATIC.chainlink![POLYGON_ID],
    },
    address: {
      [POLYGON_ID]: TOKEN_LIST.WMATIC.address[POLYGON_ID],
    },
  },
};
export default NATIVE_CURRENCY_LIST;
