export const SHORT_NAME_MAX_LENGTH = 20;

export const validShortName = (txt: string): true | string => {
  if (!new RegExp(/^[A-Za-z0-9_]+$/).test(txt)) {
    return "Nickname is only allow non-special-characters or underscore.";
  } else if (txt.length > SHORT_NAME_MAX_LENGTH) {
    return "The max length of nickname is 20";
  }
  return true;
};
