import React, { useState, useMemo } from "react";
import { get } from "lodash";
import { Box, SxProps } from "@mui/material";
import { useAccount, useNetwork } from "wagmi";
import { styled } from "@mui/system";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import { convertChainId, minAddr } from "../utils/display";
import walletMapping from "../constant/walletMapping";
import ModalWalletDetail from "./ModalWalletDetail";

interface IWalletDisplayHead {
  sx?: SxProps;
  canOpen?: boolean;
}

const WalletDisplayHead = ({ sx, canOpen = false }: IWalletDisplayHead) => {
  const { address, isConnected, connector } = useAccount();
  const { chain: walletChain } = useNetwork();
  const chainId = useMemo(() => convertChainId(walletChain?.id), [walletChain]);
  const [openDetail, setOpenDetail] = useState(false);

  if (!isConnected) {
    return null;
  }

  return (
    <>
      <StyleLayer
        sx={{
          ...sx,
          cursor: canOpen ? "pointer" : "default",
          "&:hover": {
            background: canOpen ? "rgb(92 92 92 / 55%)" : "none",
          },
        }}
        onClick={() => canOpen && setOpenDetail(true)}
      >
        {connector?.name && (
          <div className="wallet-logo">
            <img
              src={get(walletMapping, `${connector.name}.logo`, "").toString()}
              alt=""
            />
          </div>
        )}
        <div className="ac-info">
          <p>
            {connector?.id && (
              <span>
                {get(walletMapping, `${connector.name}.title`, "").toString()}
              </span>
            )}
            {chainId && (
              <span>
                @
                {get(
                  CHAIN_ID_MAPPING,
                  `${chainId}.title`,
                  walletChain?.id || ""
                ).toString()}
              </span>
            )}
          </p>
          <p>
            {chainId && (
              <span>
                {get(
                  CHAIN_ID_MAPPING,
                  `${chainId}.short`,
                  walletChain?.id || ""
                ).toString()}
              </span>
            )}
            :{minAddr(address)}
          </p>
        </div>
      </StyleLayer>
      <ModalWalletDetail open={openDetail} setOpen={setOpenDetail} />
    </>
  );
};

export default WalletDisplayHead;

const StyleLayer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "5px 10px",
  marginRight: "10px",
  position: "relative",
  ".wallet-logo": {
    width: "30px",
    marginRight: "10px",
    img: { width: "100%" },
  },
  ".ac-info": { fontSize: "14px" },
  ".ac-detail": { position: "absolute", top: "100%", right: "0" },
});
