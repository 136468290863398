import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  styled,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { get, isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useConnect } from "wagmi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import connectModalState from "../atom/connectModalState";
import walletMapping from "../constant/walletMapping";
import SsoButton from "./SsoButton";

const ModalConnectWallet = () => {
  const [open, setOpen] = useRecoilState(connectModalState);
  const { t } = useTranslation();
  const { connect, connectors } = useConnect();

  const { isConnected } = useAccount();

  const mmConnector = useMemo(
    () =>
      isArray(connectors)
        ? connectors.find((c) => c?.id === "metaMask")
        : undefined,
    [connectors]
  );
  const wcConnector = useMemo(
    () =>
      isArray(connectors)
        ? connectors.find((c) => c?.id === "walletConnect")
        : undefined,
    [connectors]
  );

  const walletLength = useMemo(() => {
    let len = 0;
    if (mmConnector?.ready) len += 1;
    if (wcConnector?.ready) len += 1;
    return len;
  }, [mmConnector, wcConnector]);

  useEffect(() => {
    if (isConnected) {
      setOpen(false);
    }
  }, [isConnected, setOpen]);

  const onConnectMM = useCallback(async () => {
    await connect({ connector: mmConnector });
  }, [connect, mmConnector]);

  const { open: openWeb3 } = useWeb3Modal();

  return (
    <StyleLayer open={!!open} onClose={() => setOpen(false)}>
      <Box className="mod-content">
        <h2>
          {t("AVA_WAL")} ({walletLength})
        </h2>
        <Grid container spacing={2} className="wal-list">
          {mmConnector?.ready && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={onConnectMM}
                disabled={!mmConnector.ready}
              >
                <img
                  src={get(
                    walletMapping,
                    `${mmConnector.name}.logo`,
                    ""
                  ).toString()}
                  alt={mmConnector.name}
                />
                <span>{mmConnector.name}</span>
              </Button>
            </Grid>
          )}
          {wcConnector?.ready && (
            <Grid item xs={6}>
              <Button variant="outlined" onClick={openWeb3}>
                <img
                  src={get(
                    walletMapping,
                    `${wcConnector.name}.logo`,
                    ""
                  ).toString()}
                  alt={wcConnector.name}
                />
                <span>{wcConnector.name}</span>
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.5)", my: "20px" }} />
        <SsoButton />
        <IconButton className="close-btn" onClick={() => setOpen(false)}>
          <AiOutlineCloseCircle />
        </IconButton>
      </Box>
    </StyleLayer>
  );
};

export default ModalConnectWallet;

const StyleLayer = styled(Modal)({
  ".wal-list": {
    marginTop: "15px",
    button: {
      width: "100%",
      textAlign: "left",
      textTransform: "none",
      paddingTop: "8px",
      paddingBottom: "8px",
      borderColor: "#fff",
      color: "#fff",
      img: { width: "30px", display: "inline-block", marginRight: "10px" },
    },
  },
});
