import { toBigInt, hexToNumber } from "web3-utils";
import { IWhitelistContract, IWhitelistContractData } from "./types";

export const WhitelistContract = (
  a: string,
  b: IWhitelistContractData
): IWhitelistContract => ({
  address: a,
  data: b,
});

export const getCheckerParam = (addr: string) =>
  toBigInt(hexToNumber(addr)) + toBigInt(Math.pow(2, 252));
