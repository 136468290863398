import { useRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import { isFunction, isNil } from "lodash";
import { jwtDecode } from "jwt-decode";
import { CredentialResponse, useGoogleOneTapLogin } from "@react-oauth/google";
import ssoUserState from "../atom/ssoUserState";
import { GOO_CRED } from "../constant/localStore";

export interface GoogleUser {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  hd: string;
  email: string;
  email_verified: boolean;
  nbf: number;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  iat: number;
  exp: number;
  jti: string;
}

const useSsoUser = () => {
  const [ssoUser, setSsoUser] = useRecoilState(ssoUserState);

  const onSuccessLoginGoogle = useCallback(
    (tokenResponse: CredentialResponse) => {
      console.log("onSuccessLoginGoogle", tokenResponse);
      const credential = tokenResponse?.credential;
      const googleUser = credential && jwtDecode<any>(credential);
      if (isNil(ssoUser) && isFunction(setSsoUser) && googleUser) {
        setSsoUser({
          ...googleUser,
          [GOO_CRED]: credential,
        });
      }
    },
    [setSsoUser, ssoUser]
  );
  return { onSuccessLoginGoogle };
};

export default useSsoUser;
