import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { configureChains, createConfig } from "wagmi";
import { arbitrum, avalanche, mainnet, polygon } from "wagmi/chains";

const chains = [arbitrum, mainnet, avalanche, polygon];
const projectId: string = process.env.REACT_APP_WALLET_CONNECT_ID || "";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ projectId, chains }),
    new MetaMaskConnector({ chains }),
    // new CoinbaseWalletConnector({ chains }),
  ],
  publicClient,
});

export const ethereumClient = new EthereumClient(wagmiConfig, chains);
