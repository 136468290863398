import { useRecoilState } from "recoil";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { isNil } from "lodash";
import profileState from "../atom/profileState";
import { disconnectAllWC } from "../utils/createWalletConnect";
import useAddressMapping from "./useAddressMapping";

const useLogout = () => {
  const [profile, setProfile] = useRecoilState(profileState);
  const { resetAddressMapping } = useAddressMapping();
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    navigate("/onboarding");
    if (!isNil(profile)) {
      setTimeout(() => {
        setProfile(null);
        resetAddressMapping();
      }, 2);
      await disconnectAllWC();
    }
  }, [navigate, profile, resetAddressMapping, setProfile]);

  return logout;
};

export default useLogout;
