import { useRecoilState } from "recoil";
import { useCallback } from "react";
import { get, isArray, isNil } from "lodash";
import axios from "axios";
import qs from "qs";
import addressMappingState, {
  IAddressMapping,
} from "../atom/addressMappingState";
import chainState from "../atom/chainState";
import profileState from "../atom/profileState";
import { compareAddr } from "../utils/display";
import ssoUserState from "../atom/ssoUserState";
import { GOO_CRED } from "../constant/localStore";

const useAddressMapping = () => {
  const [ssoUser] = useRecoilState(ssoUserState);
  const [addressMapping, setAddressMapping] =
    useRecoilState(addressMappingState);
  const [profile] = useRecoilState(profileState);
  const [chain] = useRecoilState(chainState);

  const resetAddressMapping = useCallback(() => {
    setAddressMapping([]);
  }, [setAddressMapping]);

  const onFetch = useCallback(
    async (profileAddress?: string, v_chain?: string) => {
      try {
        const p_gooCred = ssoUser?.["goo-credential"];
        const p_profileAddress = profileAddress || profile?.address;
        const p_chain = v_chain || chain;

        if (!p_gooCred || !p_profileAddress || !p_chain) {
          resetAddressMapping();
          return;
        }
        const res = await axios.get("/address", {
          baseURL: process.env.REACT_APP_BACKEND_HOST,
          headers: {
            [GOO_CRED]: p_gooCred,
          },
          params: {
            ksProfile: p_profileAddress,
            chain: p_chain,
          },
        });
        if (res.status === 200 && isArray(res.data?.data?.items)) {
          setAddressMapping(res.data.data.items);
        } else {
          resetAddressMapping();
        }
      } catch (err) {
        console.error(err);
        resetAddressMapping();
      }
    },
    [chain, profile?.address, resetAddressMapping, setAddressMapping, ssoUser]
  );

  const onUpdate = useCallback(
    async (v_address: string, v_nickName: string, v_id?: string) => {
      const p_gooCred = ssoUser?.["goo-credential"];

      if (!p_gooCred || !v_address || !v_nickName) {
        return;
      }
      const payLoad = v_id
        ? {
            id: v_id,
            nickName: v_nickName,
          }
        : {
            ksProfile: profile?.address,
            nickName: v_nickName,
            chain: chain,
            address: v_address,
          };
      const res = await axios.post("/address", qs.stringify(payLoad), {
        baseURL: process.env.REACT_APP_BACKEND_HOST,
        headers: {
          [GOO_CRED]: p_gooCred,
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      if (res.status === 200) {
        console.log(res.data?.data?.message);
      }
    },
    [chain, profile?.address, ssoUser]
  );

  const onDelete = useCallback(
    async (v_id: string) => {
      const p_gooCred = ssoUser?.["goo-credential"];

      if (!p_gooCred || !v_id) {
        return;
      }
      const res = await axios.delete(`/address/${v_id}`, {
        baseURL: process.env.REACT_APP_BACKEND_HOST,
        headers: {
          [GOO_CRED]: p_gooCred,
        },
      });
      if (res.status === 200) {
        console.log(res.data?.data?.message);
      }
    },
    [ssoUser]
  );

  const onGetKsAddr = useCallback(
    async (v_chain?: string): Promise<IAddressMapping[]> => {
      try {
        const p_gooCred = ssoUser?.["goo-credential"];
        const p_chain = v_chain || chain;

        if (!p_gooCred || !p_chain) {
          return [];
        }
        const res = await axios.get("/profile-address", {
          baseURL: process.env.REACT_APP_BACKEND_HOST,
          headers: { [GOO_CRED]: p_gooCred },
          params: { chain: p_chain },
        });
        if (res.status === 200) {
          return get(res, "data.data.items");
        } else {
          return [];
        }
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    [chain, ssoUser]
  );

  const onFindAddress = useCallback(
    (addr: string | undefined | null, addrMap?: IAddressMapping[]) => {
      if (isNil(addr) || isNil(addressMapping)) {
        return;
      }
      const findName = (isArray(addrMap) ? addrMap : addressMapping).find(
        (a: IAddressMapping) => compareAddr(a.address, addr)
      );
      return findName;
    },
    [addressMapping]
  );
  const mapAddr = useCallback(
    (addr: string | undefined | null, addrMap?: IAddressMapping[]) => {
      const findName = onFindAddress(addr, addrMap);
      return findName ? findName.nickName! : addr;
    },
    [onFindAddress]
  );

  return {
    addressMapping,
    onFetchAddress: onFetch,
    onUpdateAddress: onUpdate,
    onDeleteAddress: onDelete,
    onFindAddress,
    mapAddr,
    resetAddressMapping,
    onGetKsAddr,
  };
};

export default useAddressMapping;
