export const wcNamespaces = (address: string, chain: string) => ({
  eip155: {
    methods: [
      "eth_sendTransaction",
      "personal_sign",
      "eth_accounts",
      "eth_requestAccounts",
      "eth_sendRawTransaction",
      "eth_sign",
      "eth_signTransaction",
      "eth_signTypedData",
      "eth_signTypedData_v3",
      "eth_signTypedData_v4",
      "wallet_switchEthereumChain",
      "wallet_addEthereumChain",
      "wallet_getPermissions",
      "wallet_requestPermissions",
      "wallet_registerOnboarding",
      "wallet_watchAsset",
      "wallet_scanQRCode",
    ],
    chains: [`eip155:${parseInt(chain, 16).toString()}`],
    events: ["chainChanged", "accountsChanged", "connect", "disconnect"],
    accounts: [`eip155:${parseInt(chain, 16).toString()}:${address}`],
  },
});

export const wcMetadata = {
  name: "Knight safe",
  description:
    "Customizable self custody solution to delegate and safeguard your wallet",
  url: "https://app.knightsafe.io",
  icons: ["https://app.knightsafe.io/knight-safe-icon.png"],
};
