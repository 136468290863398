import { mapValues, pull } from "lodash";
import { ARB_ID, CHAIN_ID_MAPPING } from "./CHAIN_ID_MAPPING";

export const CHAIN_STORE: string = "ks:chain";
const ADDRESS_STORES: { [n: string]: string } = mapValues(
  CHAIN_ID_MAPPING,
  (value, key) => {
    if (key === ARB_ID) {
      return "ks:address";
    }
    return `ks:address_${value.short}`;
  }
);

export const setAddressStore = (addr: string, chain: string) => {
  const lad = localStorage.getItem(ADDRESS_STORES[chain]);
  let addrList = [];
  if (lad) {
    addrList = lad.split(",");
    addrList = pull(addrList, addr);
    addrList = [addr, ...addrList];
  } else {
    addrList = [addr];
  }
  const localAddr = addrList.join(",");
  localStorage.setItem(ADDRESS_STORES[chain], localAddr);
};

export const getAddressOptions = (chain: string) => {
  if (!chain) {
    return [];
  }
  const lad = localStorage.getItem(ADDRESS_STORES[chain]);
  if (!lad) {
    return [];
  }
  const arr = lad.split(",");
  return arr;
};

export const GOO_CRED = "goo-credential";
