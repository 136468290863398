import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { AiOutlineDown } from "react-icons/ai";

interface IMenuAccordion {
  head?: React.ReactNode;
  content?: React.ReactNode;
  iconTop?: number;
  defaultExpanded?: boolean;
}

interface IAccordionSummary extends AccordionSummaryProps {
  icontop?: number;
}

const MenuAccordion = ({
  head,
  content,
  iconTop = 13,
  defaultExpanded = false,
}: IMenuAccordion) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? true : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={(...e) => handleChange(...e)}
        defaultExpanded={false}
        disableGutters
        elevation={0}
        square
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          icontop={iconTop}
        >
          {head}
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </div>
  );
};
export default MenuAccordion;

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  background: "none",
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  ".MuiButtonBase-root": { padding: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AiOutlineDown size="0.9rem" color="#fff" />}
    {...props}
  />
))<IAccordionSummary>(({ icontop }) => ({
  backgroundColor: "nuon",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    position: "absolute",
    right: "15px",
    top: `${icontop}px`,
    color: "#fff",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: 0,
    margin: 0,
    flexDirection: "column",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  paddingTop: "0px",
  ".nav-link & > a": {
    padding: "0 0 0 33px",
    "&.active": {
      background: "none",
      color: "#8061FF",
    },
    "&.active:before": {
      content: "none",
    },
    "&:hover": {
      color: "#8061FF",
      background: "none",
    },
  },
}));
