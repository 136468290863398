import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { AbiFunctionFragment, Transaction, Web3 } from "web3";
import axios from "axios";
import { fill, flatten, get, isFunction, isNil, set } from "lodash";
import { isAddress } from "web3-validator";
import { hexToBytes } from "web3-utils";
import { isBigNumberish } from "@ethersproject/bignumber/lib/bignumber";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import {
  prepareSendTransaction,
  sendTransaction,
  waitForTransaction,
  signTypedData,
  PrepareSendTransactionArgs,
} from "@wagmi/core";
import { BigNumber } from "alchemy-sdk";
import { avalanche } from "wagmi/chains";
import chainState from "../atom/chainState";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import profileState from "../atom/profileState";
import KNIGHT_SAFE_JSON from "../constant/contract/KnightSafe_abi";
import FACTORY_JSON from "../constant/contract/KnightSafeProxyFactory_abi";
import ERC20_JSON from "../constant/contract/erc20_abi";
import { compareAddr } from "../utils/display";
import {
  IWhitelistContractMap,
  IWhitelistFunctionMap,
} from "../constant/functions/types";
import { checkTokenIsOnlist } from "../utils/appFunction";
import {
  getAddTokenFuncParam,
  getContractDetail,
} from "../utils/contractAddressDetail";

export const abiScheme = (n: string, abi: any = KNIGHT_SAFE_JSON) =>
  abi.find((w: any) => w?.name === n);

const waitGnosisHash = async (url: string) => {
  let txHash = null;
  let retryCount = 0;

  while (!txHash && retryCount < 5) {
    try {
      await new Promise((r) => setTimeout(r, 500));
      const res = await axios.get(url);
      if (res?.data?.transactionHash) {
        txHash = res.data.transactionHash;
      }
    } catch (error) {
      console.error("waitGnosisHash:", error);
    }

    retryCount++;
  }

  return txHash;
};

const onEncodeSetupABI = (
  accounts: string[],
  traderAddress: string[],
  whiteAddress: string[],
  appControl: string[],
  accessList: string[],
  isRefund: boolean,
  FUNCTION_MAP: IWhitelistFunctionMap,
  TOKEN_WHITELIST_MAP: IWhitelistContractMap,
  web3: any
) => {
  const initAddresses: string[] = [];
  const initSelectorLists: Uint8Array[][] = [];
  const initParametersLists: (number | bigint)[][][] = [];

  whiteAddress.forEach((c) => {
    initAddresses.push(c);
    initSelectorLists.push([]);
    initParametersLists.push([]);
  });
  appControl.forEach((c) => {
    initAddresses.push(...FUNCTION_MAP[c].map((p) => p.address));
    initSelectorLists.push(
      ...FUNCTION_MAP[c].map((e) =>
        Object.keys(e.data).map((px) => hexToBytes(px))
      )
    );
    initParametersLists.push(
      ...FUNCTION_MAP[c].map((e) => Object.values(e.data))
    );
  });

  accessList.forEach((c) => {
    initAddresses.push(get(TOKEN_WHITELIST_MAP[c], "address"));
    initSelectorLists.push(
      Object.keys(get(TOKEN_WHITELIST_MAP[c], "data")).map((s) => hexToBytes(s))
    );
    initParametersLists.push(
      Object.values(get(TOKEN_WHITELIST_MAP[c], "data"))
    );
  });

  const encodeSetupABI = web3.eth.abi.encodeFunctionCall(abiScheme("setup"), [
    accounts[0],
    traderAddress,
    initAddresses,
    initSelectorLists,
    initParametersLists,
    isRefund,
  ]);
  return encodeSetupABI;
};

const useAbi = () => {
  const [chain] = useRecoilState(chainState);
  const [profile] = useRecoilState(profileState);
  const { address } = useAccount();
  const { chain: walletChain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();

  const getReceipt = useCallback(
    async (txHash: `0x${string}`) => {
      let tx;
      try {
        tx = await waitForTransaction({ hash: txHash });
      } catch (e) {
        console.error(e);
      }
      if (!tx && chain) {
        // may be gnosis hash
        try {
          const safeToTxHash = await waitGnosisHash(
            `${CHAIN_ID_MAPPING[chain].gnosisApi}/api/v1/multisig-transactions/${txHash}`
          );
          if (safeToTxHash) {
            tx = await waitForTransaction({ hash: safeToTxHash });
          }
        } catch (e) {
          console.error(e);
        }
      }
      if (!tx || tx.status !== "success") {
        // eslint-disable-next-line no-throw-literal
        throw {
          innerError: {
            message: "execution reverted: transcation_reverted",
          },
          txHash: tx?.transactionHash,
        };
      }
      return tx;
    },
    [chain]
  );
  const getGas = useCallback(
    async (transactionParameters: Transaction, web3: Web3) => {
      const gas_num = await web3.eth.estimateGas(transactionParameters);
      if (walletChain?.id === avalanche.id) {
        return web3.utils.numberToHex(
          BigNumber.from(gas_num).mul(10).div(8).toBigInt()
        );
      }
      return web3.utils.numberToHex(gas_num);
    },
    [walletChain]
  );

  const abiReady = useCallback(async () => {
    const chain10 = parseInt(chain, 16);

    const accounts: `0x${string}`[] = [];
    if (address) {
      accounts.push(address);
    }
    if (!accounts || !accounts.length || !chain) {
      throw new Error("No connected wallet");
    }

    if (walletChain?.id !== chain10 && isFunction(switchNetworkAsync)) {
      switchNetworkAsync!(chain10);
    }

    return accounts;
  }, [address, chain, switchNetworkAsync, walletChain?.id]);

  const onUpdateWhiteTrader = useCallback(
    async (toRemoveArr: string[] = [], toAddArr: string[] = []) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const contract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );
      const encodeABI = (await contract.methods
        .batchUpdateTraders(toRemoveArr, toAddArr)
        .encodeABI()) as `0x${string}`;
      const transactionParameters = {
        to: profile!.address,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);

      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onChangeRefund = useCallback(
    async (changeTo: boolean) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const contract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );
      const encodeABI = await contract.methods
        .setOwnerRefundGasSpentToSender(changeTo)
        .encodeABI();
      const transactionParameters = {
        to: profile!.address,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);
      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onUpdateToken = useCallback(
    async (toRemove: string[] = [], toAdd: string[] = []) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const contract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );
      const TOKEN_WHITELIST_MAP = CHAIN_ID_MAPPING[chain].tokenMapping;

      const param1 = toRemove.map((c) =>
        isAddress(c) ? c : get(TOKEN_WHITELIST_MAP[c], "address")
      );

      // handle param for ADD
      const param2: string[] = [];
      const param3: Uint8Array[][] = [];
      const param4: (number | bigint)[][][] = [];
      for (let i = 0; i < toAdd.length; i++) {
        const c = toAdd[i];
        if (!isAddress(c)) {
          param2.push(get(TOKEN_WHITELIST_MAP[c], "address"));
          param3.push(
            Object.keys(get(TOKEN_WHITELIST_MAP[c], "data")).map((s) =>
              web3.utils.hexToBytes(s)
            )
          );
          param4.push(Object.values(get(TOKEN_WHITELIST_MAP[c], "data")));
        } else {
          const addrData = await getContractDetail(c, chain);
          if (isNil(addrData)) {
            throw new Error("Get contract detail failed");
          }
          const pd = getAddTokenFuncParam(addrData.writeEvt);
          param2.push(c);
          param3.push(Object.keys(pd).map((s) => web3.utils.hexToBytes(s)));
          param4.push(Object.values(pd));
        }
      }

      const encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("batchUpdateWhitelistAddresses") as AbiFunctionFragment,
        [param1, param2, param3, param4]
      );

      const transactionParameters = {
        to: profile!.address,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);
      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onUpdateWhiteWallet = useCallback(
    async (toRemove: string[] = [], toAdd: string[] = []) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const contract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );

      const emptyParam = fill(Array(toAdd.length), []);
      const encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("batchUpdateWhitelistAddresses") as AbiFunctionFragment,
        [toRemove, toAdd, emptyParam, emptyParam]
      );

      const transactionParameters = {
        to: profile!.address,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);
      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onUpdateApp = useCallback(
    async (toRemove: string[] = [], toAdd: string[] = []) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const contract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );
      const FUNCTION_MAP = CHAIN_ID_MAPPING[chain].functionMapping;

      const param1 = flatten(
        toRemove.map((c) => {
          if (profile?.updateApp.includes(c)) {
            const outdate = CHAIN_ID_MAPPING[
              chain
            ]?.functionMappingOutdate?.find((obj) => !!obj[c])?.[c];
            if (!isNil(outdate)) {
              return outdate.map((p) => p.address);
            }
          }
          return FUNCTION_MAP[c].map((p) => p.address);
        })
      );
      const param2 = flatten(
        toAdd.map((c) => FUNCTION_MAP[c].map((p) => p.address))
      );
      const param3 = flatten(
        toAdd.map((c) =>
          FUNCTION_MAP[c].map((e) =>
            Object.keys(e.data).map((px) => web3.utils.hexToBytes(px))
          )
        )
      );
      const param4 = flatten(
        toAdd.map((c) => FUNCTION_MAP[c].map((e) => Object.values(e.data)))
      );

      const encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("batchUpdateWhitelistAddresses") as AbiFunctionFragment,
        [param1, param2, param3, param4]
      );

      const transactionParameters = {
        to: profile!.address,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);
      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onChangeOwner = useCallback(
    async (addr: string) => {
      const accounts = await abiReady();

      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const contract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );

      const encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("transferOwnership") as AbiFunctionFragment,
        [addr]
      );

      const transactionParameters = {
        to: profile!.address,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);
      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onAcceptPendingOwner = useCallback(async () => {
    const accounts = await abiReady();
    const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
    const contract = new web3.eth.Contract(KNIGHT_SAFE_JSON, profile?.address);

    const encodeABI = web3.eth.abi.encodeFunctionCall(
      abiScheme("acceptOwnership") as AbiFunctionFragment,
      []
    );

    const transactionParameters = {
      to: profile!.address,
      from: accounts[0],
      data: encodeABI,
      value: BigInt(0),
    };
    const nonce = await web3.eth.getTransactionCount(accounts[0]);
    set(transactionParameters, "nonce", web3.utils.toHex(nonce));
    const gas = await getGas(transactionParameters, web3);
    set(transactionParameters, "gas", gas);

    const config = await prepareSendTransaction(
      transactionParameters as PrepareSendTransactionArgs
    );
    const { hash } = await sendTransaction(config);
    const rc = await getReceipt(hash);
    return rc;
  }, [abiReady, chain, getReceipt, getGas, profile]);

  const onExecTransaction = useCallback(
    async (exceTo: string, exceValue: number | bigint, exceData: string) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
      const knightContract = new web3.eth.Contract(
        KNIGHT_SAFE_JSON,
        profile?.address
      );
      const knight_encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("execTransaction") as AbiFunctionFragment,
        [exceTo, exceValue, exceData]
      );
      const knight_transactionParameters = {
        from: accounts[0],
        to: profile!.address,
        value: BigInt(0),
        data: knight_encodeABI,
      };

      const knight_gas = await getGas(knight_transactionParameters, web3);
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(knight_transactionParameters, "nonce", web3.utils.toHex(nonce));
      set(knight_transactionParameters, "gas", knight_gas);

      const config = await prepareSendTransaction(
        knight_transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);
      return rc;
    },
    [abiReady, chain, getReceipt, getGas, profile]
  );

  const onTransfer = useCallback(
    async (coinAddress: string, targetAddress: string, am: BigNumber) => {
      const amount = am;
      if (
        !coinAddress ||
        !isAddress(coinAddress) ||
        !targetAddress ||
        !isAddress(targetAddress)
      ) {
        throw new Error("wrong recipient");
      }
      if (!amount || !isBigNumberish(amount)) {
        throw new Error("wrong amount");
      }
      // if (!checkTokenIsOnlist(coinAddress, chain)) {
      //   throw new Error("contract not on list");
      // }

      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);

      const erc20_encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("transfer", ERC20_JSON) as AbiFunctionFragment,
        [targetAddress, amount.toBigInt()]
      );

      const rc = await onExecTransaction(coinAddress, 0, erc20_encodeABI);
      return rc;
    },
    [chain, onExecTransaction]
  );

  const onSendNativeCurrency = useCallback(
    async (targetAddress: string, am: BigNumber) => {
      const amount = am;
      if (!targetAddress || !isAddress(targetAddress)) {
        throw new Error("wrong recipient");
      }
      if (!amount || !isBigNumberish(amount)) {
        throw new Error("wrong amount");
      }

      const rc = await onExecTransaction(
        targetAddress,
        amount.toBigInt(),
        "0x"
      );
      return rc;
    },
    [onExecTransaction]
  );

  const onCreateNewWallet = useCallback(
    async (
      traderAddress: string[],
      whiteAddress: string[],
      appControl: string[],
      accessList: string[],
      isRefund: boolean
    ) => {
      const accounts = await abiReady();
      const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);

      const factoryContract = new web3.eth.Contract(
        FACTORY_JSON,
        CHAIN_ID_MAPPING[chain].factoryAddr
      );

      const TOKEN_WHITELIST_MAP = CHAIN_ID_MAPPING[chain].tokenMapping;
      const FUNCTION_MAP = CHAIN_ID_MAPPING[chain].functionMapping;

      const encodeSetupABI = onEncodeSetupABI(
        accounts,
        traderAddress,
        whiteAddress,
        appControl,
        accessList,
        isRefund,
        FUNCTION_MAP,
        TOKEN_WHITELIST_MAP,
        web3
      );

      const encodeABI = web3.eth.abi.encodeFunctionCall(
        abiScheme("createProxy", FACTORY_JSON),
        [CHAIN_ID_MAPPING[chain].masterAddr, encodeSetupABI]
      );
      // const acNo = await getTransactionCount(accounts[0]);
      // console.log("541 ~ useAbi ~ acNo:", acNo);
      const transactionParameters = {
        to: CHAIN_ID_MAPPING[chain].factoryAddr,
        from: accounts[0],
        data: encodeABI,
        value: BigInt(0),
      };
      const nonce = await web3.eth.getTransactionCount(accounts[0]);
      set(transactionParameters, "nonce", web3.utils.toHex(nonce));
      const gas = await getGas(transactionParameters, web3);
      set(transactionParameters, "gas", gas);

      const config = await prepareSendTransaction(
        transactionParameters as PrepareSendTransactionArgs
      );
      const { hash } = await sendTransaction(config);
      const rc = await getReceipt(hash);

      const rcm = rc.logs.find((d) =>
        compareAddr(d.address, CHAIN_ID_MAPPING[chain].factoryAddr)
      );
      const bornAddress = "0x" + rcm!.data.slice(26, 66);
      console.log("624 ~ useAbi ~ bornAddress:", bornAddress);
      if (!bornAddress) {
        throw new Error("No account found");
      }
      return bornAddress;
    },
    [abiReady, chain, getReceipt, getGas]
  );

  const onSignDataV4 = useCallback(async (signBody: any) => {
    if (get(signBody, "domain.chainId")) {
      set(signBody, "domain.chainId", Number(get(signBody, "domain.chainId")));
    }
    const signedMessage = await signTypedData(signBody);
    return signedMessage;
  }, []);

  return {
    onUpdateWhiteTrader,
    onChangeRefund,
    onUpdateToken,
    onUpdateWhiteWallet,
    onUpdateApp,
    onChangeOwner,
    onAcceptPendingOwner,
    onExecTransaction,
    onTransfer,
    onSendNativeCurrency,
    onCreateNewWallet,
    onSignDataV4,
  };
};

export default useAbi;
