import React, { useMemo } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalProps,
  Typography,
  styled,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { get, isFunction, isString } from "lodash";
import confirmModalState from "../atom/confirmModalState";

interface ICustomModal extends ModalProps {
  size: "sm" | "md";
}
const ModalConfirm = () => {
  const [open, setOpen] = useRecoilState(confirmModalState);

  const dm = useMemo(() => {
    if (!open) {
      return {};
    }
    return {
      title: open?.title || "Confirmation",
      message: !open?.message ? null : isString(open.message) ? (
        <Typography component="p">{open.message}</Typography>
      ) : (
        open.message
      ),
      yesText: open?.yesText || "Yes",
      yes: () => {
        if (isFunction(open?.yes)) {
          open.yes();
        }
        setOpen(false);
      },
      noText: open?.noText || "No",
      no: () => {
        if (isFunction(open?.no)) {
          open.no();
        }
        setOpen(false);
      },
      size: open?.size || "sm",
    };
  }, [open, setOpen]);

  return (
    <CustomModal
      open={!!open}
      onClose={() => setOpen(false)}
      size={dm?.size || "sm"}
    >
      <Box className="mod-content">
        <h2>{dm.title}</h2>
        <Box sx={{ minHeight: dm.size === "md" ? "300px" : "200px" }}>
          {dm.message}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={dm.yes}>{dm.yesText}</Button>
          <Button onClick={dm.no}>{dm.noText}</Button>
        </Box>
        <IconButton className="close-btn" onClick={() => setOpen(false)}>
          <AiOutlineCloseCircle />
        </IconButton>
      </Box>
    </CustomModal>
  );
};

export default ModalConfirm;

const CustomModal = styled(Modal)<ICustomModal>(({ size }) => ({
  ".mod-content": {
    width: size === "md" ? "550px" : "380px",
  },
  ".wal-list": {
    marginTop: "15px",
    button: {
      width: "100%",
      textAlign: "left",
      textTransform: "none",
      paddingTop: "8px",
      paddingBottom: "8px",
      borderColor: "#fff",
      color: "#fff",
      img: { width: "30px", display: "inline-block", marginRight: "10px" },
    },
  },
}));
