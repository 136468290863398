import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../lang/en.json";

const config: InitOptions = {
  resources: {
    en: {
      main: en,
    },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  saveMissing: true, // send not translated keys to endpoint
  debug: false,
  // whitelist: ["en"],
  react: {
    useSuspense: false,
  },
  defaultNS: "main",
}

i18n
  .use(initReactI18next)
  .init(config);
