import Web3 from "web3";
import {
  difference,
  flatten,
  isEqual,
  isFunction,
  isNil,
  map,
  pickBy,
  pullAll,
  values,
  flattenDepth,
} from "lodash";
import { fromWei, toChecksumAddress } from "web3-utils";
import Moralis from "moralis";
import { utils } from "ethers";
import KNIGHT_SAFE_JSON from "../constant/contract/KnightSafe_abi";
import CHAIN_ID_MAPPING, { ARB_ID } from "../constant/CHAIN_ID_MAPPING";
import { checkIsKnightSafe } from "./checkIsKnightSafe";
import {
  CONTRACT_SUFFIX,
  EOA_SUFFIX,
  KS_WALLET_SUFFIX,
} from "../constant/address";
import { IProfile } from "../atom/profileState";
import { getErc20BasicInfo } from "./contractAddressDetail";

interface IMoralisTokenBalance {
  token_address: string;
  name: string;
  symbol: string;
  logo?: string | undefined;
  thumbnail?: string | undefined;
  decimals: number;
  balance: string;
  possible_spam: boolean;
  verified_collection?: boolean | undefined;
}

const sumArr = (arr: string[]) => arr.map((a) => toChecksumAddress(a));

const strArr = (arr: any[]): string[] =>
  arr.map((s: any) => (!Array.isArray(s) ? s.toString() : strArr(s)));

const getKnightSafeProfile = async (
  addr: `0x${string}`,
  chain: string,
  errorCallBack?: Function
): Promise<IProfile | null> => {
  const startTime = Date.now();
  try {
    const web3 = new Web3(CHAIN_ID_MAPPING[chain].rpc);
    const contract = new web3.eth.Contract(KNIGHT_SAFE_JSON, addr);
    // const alchemyConfig = {
    //   apiKey: process.env.REACT_APP_ALCHEMY_KEY,
    //   network: CHAIN_ID_MAPPING[chain].alchemyNetwork,
    // };

    /* speacial handling */
    const getFuncPickCheck = (wca: any) =>
      Promise.all(
        wca.map((wc: any) =>
          // @ts-ignore
          contract.methods
            .getWhitelistFunctionParametersMultiple(
              wc.address,
              Object.keys(wc.data)
            )
            .call()
        )
      );
    const gmxV2Checking = async () => {
      const funcName = "GMX_V2";
      const oldMaps = CHAIN_ID_MAPPING[chain].functionMappingOutdate?.filter(
        (obj) => !!obj[funcName]
      );
      if (chain !== ARB_ID || isNil(oldMaps)) {
        return false;
      }
      let bool = false;
      const resFuncsPromise = oldMaps.map((oldMap) =>
        getFuncPickCheck(oldMap[funcName])
      );
      let resFuncs;
      try {
        resFuncs = await Promise.all(resFuncsPromise);
      } catch (e) {
        return false;
      }

      for (let i = 0; i < oldMaps.length; i++) {
        const oldMap = oldMaps[i];
        const mappingFuncOld = strArr(
          oldMap[funcName].map((po) => values(po.data))
        );
        const isEq = isEqual(mappingFuncOld, strArr(resFuncs[i]));
        if (isEq) {
          bool = true;
          break;
        }
      }
      return bool;
    };
    const aaveLendChecking = async (): Promise<boolean> => {
      const funcName = "AAVE_LEND";
      const oldMaps = CHAIN_ID_MAPPING[chain].functionMappingOutdate?.filter(
        (obj) => !!obj[funcName]
      );
      if (chain !== ARB_ID || isNil(oldMaps)) {
        return false;
      }
      let bool = false;
      const resFuncsPromise = oldMaps.map((oldMap) =>
        getFuncPickCheck(oldMap[funcName])
      );
      let resFuncs;
      try {
        resFuncs = await Promise.all(resFuncsPromise);
      } catch (e) {
        return false;
      }
      for (let i = 0; i < oldMaps.length; i++) {
        const oldMap = oldMaps[i];
        const mappingFuncOld = strArr(
          oldMap[funcName].map((po) => values(po.data))
        );
        const isEq = isEqual(mappingFuncOld, strArr(resFuncs[i]));
        if (isEq) {
          bool = true;
          break;
        }
      }
      return bool;
    };

    const uniSwapChecking = async (): Promise<boolean> => {
      const funcName = "UNISWAP_SWAP";
      const oldMap = CHAIN_ID_MAPPING[chain].functionMappingOutdate?.find(
        (obj) => !!obj[funcName]
      );
      if (chain === ARB_ID && !isNil(oldMap)) {
        const mappingFuncOld = strArr(
          oldMap[funcName].map((po) => values(po.data))
        );
        try {
          const resFunc = strArr(await getFuncPickCheck(oldMap[funcName]));
          return isEqual(mappingFuncOld, resFunc);
        } catch (err) {
          return false;
        }
      }
      return false;
    };
    const speacialCheckingFuncPick = (
      whitelists: string[],
      chain: string,
      name: string
    ): boolean => {
      let bool = false;
      if (chain === ARB_ID) {
        const oldMaps = CHAIN_ID_MAPPING[chain].functionMappingOutdate?.filter(
          (obj) => !!obj[name]
        );
        if (!oldMaps) {
          return false;
        }
        for (let i = 0; i < oldMaps.length; i++) {
          const oldMap = oldMaps[i];

          const ya = oldMap[name].map((c) => c.address);
          const noDiff = difference(ya, whitelists).length < 1;
          if (noDiff) {
            bool = true;
          }
        }

        // .map((c) => c.address);
      }
      return bool;
    };
    /* speacial handling */

    const moralisConfig = {
      address: addr,
      chain: CHAIN_ID_MAPPING[chain].moralisNetwork,
    };

    const [
      ownerRes,
      pendingOwnerRes,
      isGasRefundRes,
      tradersRes,
      whiteAddrRes,
      weiBalanceRes,
      tokensForOwnerRes,
    ] = await Promise.all<any>([
      contract.methods.getOwner().call(),
      contract.methods.getPendingOwner().call(),
      contract.methods.getIsGasRefund().call(),
      contract.methods.getTraders().call(),
      contract.methods.getWhitelistAddresses().call(),
      Moralis.EvmApi.balance.getNativeBalance(moralisConfig),
      Moralis.EvmApi.token.getWalletTokenBalances(moralisConfig),
    ]);
    const whitelists = sumArr([...whiteAddrRes]);

    let whitelist_app_list: string[] = [];
    let whitelist_app_update_list: string[] = [];
    let whitelist_token_list: string[] = [];
    let whitelist_wallet_list: string[] = [];
    let whitelist_others_list: string[] = [];
    let knigihtSafe_wallet_list: string[] = [];

    const APP_FUNCTION_WHITELIST_MAP = CHAIN_ID_MAPPING[chain].functionMapping;
    const TOKEN_WHITELIST_MAP = CHAIN_ID_MAPPING[chain].tokenMapping;

    //region Check for App
    const funcPick = pickBy(APP_FUNCTION_WHITELIST_MAP, (e, key) => {
      const yyt = sumArr(e.map((c) => c.address));
      if (
        difference(yyt, whitelists).length < 1 ||
        // speacial handling
        (key === "UNISWAP_SWAP" &&
          speacialCheckingFuncPick(whitelists, chain, "UNISWAP_SWAP")) ||
        (key === "AAVE_LEND" &&
          speacialCheckingFuncPick(whitelists, chain, "AAVE_LEND")) ||
        (key === "GMX_V2" &&
          speacialCheckingFuncPick(whitelists, chain, "GMX_V2"))
      ) {
        return true;
      }
    });
    const funcPickAddrList = flatten(
      map(funcPick, (e) => e.map((f) => toChecksumAddress(f.address)))
    );

    const funcPickCheck = async (wca: any, key: string) => {
      const res = await getFuncPickCheck(wca);
      const mappingFunc = strArr(
        APP_FUNCTION_WHITELIST_MAP[key].map((po) => values(po.data))
      );
      const resFunc = strArr(res);
      if (isEqual(mappingFunc, resFunc)) {
        whitelist_app_list.push(key);
      }
    };

    // region Check for Token
    const tokenPick = pickBy(TOKEN_WHITELIST_MAP, (e) =>
      whitelists
        .map((p) => toChecksumAddress(p))
        .includes(toChecksumAddress(e.address))
    );
    const tokenPickAddrList = map(tokenPick, (s) =>
      toChecksumAddress(s.address)
    );
    const tokenPickCheck = async (wc: any, key: string) => {
      // @ts-ignore
      const res: string[] = await contract.methods
        .getWhitelistFunctionParametersMultiple(
          wc.address,
          Object.keys(wc.data)
        )
        .call();
      const mappingFunc = strArr(values(TOKEN_WHITELIST_MAP[key].data));
      const resFunc = strArr(res);
      if (isEqual(mappingFunc, resFunc)) {
        whitelist_token_list.push(key);
      }
    };

    // not show outdata address
    let outdateList: string[] = [];
    if (CHAIN_ID_MAPPING[chain]?.functionMappingOutdate) {
      outdateList = flattenDepth(
        CHAIN_ID_MAPPING[chain].functionMappingOutdate!.map(
          (obj) => Object.values(obj)[0]
        ),
        2
      ).map((c) => c.address);
    }

    const otherAddrList = pullAll(whitelists, [
      ...funcPickAddrList,
      ...tokenPickAddrList,
      ...outdateList,
    ]);
    const otherCheck = async (wc: string) => {
      const isKnightSafe = await checkIsKnightSafe(wc, chain);
      if (isKnightSafe) {
        knigihtSafe_wallet_list.push(`${wc}--${KS_WALLET_SUFFIX}`);
        return;
      }
      const addrCode = await web3.eth.getCode(wc);
      if (addrCode.length <= 2) {
        whitelist_wallet_list.push(`${wc}--${EOA_SUFFIX}`);
        return;
      }
      let isErc20 = false;
      try {
        const erc20Info = await getErc20BasicInfo(wc, chain);
        if (erc20Info?.name && erc20Info.decimals && erc20Info.symbol) {
          isErc20 = true;
        }
      } catch (err) {}
      if (isErc20) {
        whitelist_token_list.push(wc);
        return;
      }
      whitelist_wallet_list.push(`${wc}--${CONTRACT_SUFFIX}`);
    };
    await Promise.all([
      ...map(funcPick, funcPickCheck),
      ...map(tokenPick, tokenPickCheck),
      ...map(otherAddrList, otherCheck),
    ]);
    if (!whitelist_app_list.includes("GMX_V2") && funcPick["GMX_V2"]) {
      if (await gmxV2Checking()) {
        whitelist_app_list.push("GMX_V2");
        whitelist_app_update_list.push("GMX_V2");
      }
    }
    if (!whitelist_app_list.includes("AAVE_LEND") && funcPick["AAVE_LEND"]) {
      if (await aaveLendChecking()) {
        whitelist_app_list.push("AAVE_LEND");
        whitelist_app_update_list.push("AAVE_LEND");
      }
    }
    if (
      !whitelist_app_list.includes("UNISWAP_SWAP") &&
      funcPick["UNISWAP_SWAP"]
    ) {
      if (await uniSwapChecking()) {
        whitelist_app_list.push("UNISWAP_SWAP");
        whitelist_app_update_list.push("UNISWAP_SWAP");
      }
    }

    // region Check for Wallet or Others
    const data: IProfile = {
      address: addr,
      owner: ownerRes,
      pendingOwner: pendingOwnerRes,
      isGasRefund: isGasRefundRes,
      traders: tradersRes,
      app: whitelist_app_list.sort(),
      updateApp: whitelist_app_update_list.sort(),
      token: whitelist_token_list.sort(),
      wallet: [
        ...knigihtSafe_wallet_list.sort(),
        ...whitelist_wallet_list.sort(),
      ],
      other: whitelist_others_list.sort(),
      nativeBalance: weiBalanceRes?.raw?.balance
        ? fromWei(weiBalanceRes.raw.balance, "ether")
        : "",
      tokensForOwner: tokensForOwnerRes?.raw
        ? tokensForOwnerRes.raw
            .filter((p: IMoralisTokenBalance) => +p.balance > 0)
            .map((e: IMoralisTokenBalance) => ({
              ...e,
              balance: utils.formatUnits(e.balance, e.decimals),
              contractAddress: e.token_address,
            }))
        : [],
      lastUpdateTime: new Date(),
    };

    console.log("need time: ", Math.floor(Date.now()) - Math.floor(startTime));
    return data;
  } catch (e) {
    console.error(e);
    if (isFunction(errorCallBack)) {
      errorCallBack(e);
    }
    return null;
  }
};

export default getKnightSafeProfile;
