import { Box, IconButton } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { FiCopy } from "react-icons/fi";
import { MdOutlineQrCode } from "react-icons/md";
import { get, isArray, isNil, isString, sum } from "lodash";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { BsFillShareFill } from "react-icons/bs";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import { useCopyToClipboard } from "react-use";
import profileState from "../atom/profileState";
import chainState from "../atom/chainState";
import CHAIN_ID_MAPPING from "../constant/CHAIN_ID_MAPPING";
import { displayPrice, getPriceToUsD } from "../utils/display";
import qrCodeModalState from "../atom/qrCodeModalState";
import useGetTokenRate, {
  getNativeCurrencyAddr,
} from "../hooks/useGetTokenRate";
import tokenRateState from "../atom/tokenRateState";
import debank_logo from "../assets/images/debank.png";
import myLinkModalState from "../atom/myLinkModalState";
import ModifyAddress from "./ModifyAddress";

const LeftMetaData = () => {
  const [profile] = useRecoilState(profileState);
  const [chain] = useRecoilState(chainState);
  const setOpenQr = useSetRecoilState(qrCodeModalState);
  const { enqueueSnackbar } = useSnackbar();
  const { getTokenRate, getNativeCurrencyRate } = useGetTokenRate();
  const [tokenRate] = useRecoilState(tokenRateState);
  const { t } = useTranslation();
  const setOpenMyLink = useSetRecoilState(myLinkModalState);
  const [_, copyToClipboard] = useCopyToClipboard();

  const addr = useMemo(() => profile?.address, [profile?.address]);

  useEffect(() => {
    const fetchRate = async () => {
      if (isArray(profile?.tokensForOwner)) {
        const toGetRate = profile!.tokensForOwner.map((tp) =>
          getTokenRate(tp?.contractAddress)
        );
        await Promise.all([getNativeCurrencyRate(), ...toGetRate]);
      }
    };
    fetchRate();
  }, [profile]);

  const totalValue = useMemo(() => {
    if (!profile?.nativeBalance || !isArray(profile?.tokensForOwner)) {
      return 0;
    }
    const nativeCurrencyAddr = getNativeCurrencyAddr(chain);
    const nativeCurrencyPrice = isNil(nativeCurrencyAddr)
      ? 0
      : getPriceToUsD(profile.nativeBalance, tokenRate[nativeCurrencyAddr]);
    const otherPrice = profile.tokensForOwner.map((t) =>
      getPriceToUsD(t.balance, tokenRate[t.contractAddress!])
    );
    return sum([nativeCurrencyPrice, ...otherPrice]);
  }, [chain, profile, tokenRate]);

  return (
    <StyleLayer>
      <p>{t("SIGNLE_T")}</p>
      <p>
        <ModifyAddress
          address={profile?.address as string}
          showShort
          alwaysShort
          prefix={<b>{`${get(CHAIN_ID_MAPPING, `${chain}.short`, "")}:`}</b>}
        />
      </p>
      <p>
        <b>{displayPrice(totalValue, 1)} USD</b>
      </p>
      <div className="btns">
        <IconButton onClick={() => setOpenQr(true)}>
          <MdOutlineQrCode />
        </IconButton>
        <IconButton
          onClick={() => {
            copyToClipboard(isString(addr) ? addr : "");
            enqueueSnackbar(t("COPIED"));
          }}
        >
          <FiCopy />
        </IconButton>
        <IconButton onClick={() => setOpenMyLink(true)}>
          <BsFillShareFill size={18} />
        </IconButton>
        <IconButton
          href={
            profile?.address
              ? get(CHAIN_ID_MAPPING, `${chain}.scan`) +
                "address/" +
                profile.address
              : ""
          }
          target="_blank"
        >
          <img
            src={get(CHAIN_ID_MAPPING, `${chain}.scanLogo`, "").toString()}
            alt="scan"
            width={20}
          />
        </IconButton>
        <IconButton
          href={
            profile?.address
              ? get(CHAIN_ID_MAPPING, `${chain}.debank`) + profile.address
              : ""
          }
          target="_blank"
        >
          <img src={debank_logo} alt="debank" width={16} />
        </IconButton>
      </div>
    </StyleLayer>
  );
};

export default LeftMetaData;

const StyleLayer = styled(Box)``;
