import React, { ReactNode } from "react";
import { NavLink, NavLinkProps, useSearchParams } from "react-router-dom";

interface INavAppLink extends NavLinkProps {
  children?: ReactNode;
  to: string;
}

const NavAppLink = ({ children, to, ...props }: INavAppLink) => {
  const [search] = useSearchParams();
  return (
    <NavLink {...props} to={search.size ? `${to}?${search.toString()}` : to}>
      {children}
    </NavLink>
  );
};

export default NavAppLink;
