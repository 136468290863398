import { Core } from "@walletconnect/core";
import { IWeb3Wallet, Web3Wallet } from "@walletconnect/web3wallet";
import { wcMetadata } from "../constant/walletConnectNamespaces";

export let web3wallet: IWeb3Wallet;
export let core: any;

export async function createWeb3Wallet() {
  core = new Core({
    projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
  });

  web3wallet = await Web3Wallet.init({
    core,
    metadata: { ...wcMetadata, url: window.location.hostname },
  });
  return web3wallet;
}

function clearIndexedDBData(databaseName: string) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(databaseName);

    request.onerror = function (event: any) {
      reject("Error opening IndexedDB database: " + event?.target?.errorCode);
    };

    request.onsuccess = function (event: any) {
      const db = event.target.result;
      const transaction = db.transaction(db.objectStoreNames, "readwrite");

      transaction.onerror = function (event: any) {
        reject("Error starting IndexedDB transaction: " + event.target.error);
      };

      transaction.oncomplete = function () {
        resolve("IndexedDB data cleared successfully.");
      };

      for (const objectStoreName of db.objectStoreNames) {
        const objectStore = transaction.objectStore(objectStoreName);
        const clearRequest = objectStore.clear();

        clearRequest.onerror = function (event: any) {
          reject(
            "Error clearing data from IndexedDB object store: " +
              event.target.error
          );
        };
      }
    };
  });
}

const WC_INDB_NAME = "WALLET_CONNECT_V2_INDEXED_DB";

export const disconnectAllWC = async () => {
  if (!web3wallet) {
    return;
  }
  try {
    console.log("onDelete disconnectAllWC");
    const actSes = await web3wallet.getActiveSessions();
    await Promise.all(
      Object.values(actSes).map((e) =>
        web3wallet.disconnectSession({
          topic: e.topic,
          reason: {
            code: 0,
            message: "User disconnected",
          },
        })
      )
    );
  } catch (error) {
    console.log("onDelete disconnectAllWC fail");
    console.error(error);
    try {
      await clearIndexedDBData(WC_INDB_NAME);
      console.log("onDelete", WC_INDB_NAME);
    } catch (err) {
      console.log("onDelete fail", WC_INDB_NAME);
      console.error(err);
    }
  }
};
