import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { FaGoogle } from "react-icons/fa";
import { isNil } from "lodash";
import { useRecoilState } from "recoil";
import ssoUserState from "../atom/ssoUserState";
import useSsoUser from "../hooks/useSsoUser";
import useAddressMapping from "../hooks/useAddressMapping";

interface ISsoButton {
  position?: "left" | "center";
}

const SsoButton = ({ position = "left" }: ISsoButton) => {
  const [ssoUser, setSsoUser] = useRecoilState(ssoUserState);
  const { resetAddressMapping } = useAddressMapping();
  const { onSuccessLoginGoogle } = useSsoUser();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: position === "left" ? "flex-start" : "center",
        py: "5px",
      }}
    >
      {!isNil(ssoUser) ? (
        <>
          <FaGoogle size={26} style={{ marginRight: "10px" }} />
          <Typography>
            Welcome, {ssoUser.given_name || ssoUser.email}
          </Typography>
          <Button
            variant="text"
            sx={{ ml: "auto" }}
            onClick={() => {
              googleLogout();
              setSsoUser(null);
              resetAddressMapping();
            }}
          >
            Logout
          </Button>
        </>
      ) : (
        <GoogleLogin
          onSuccess={onSuccessLoginGoogle}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      )}
    </Box>
  );
};

export default SsoButton;
